import React from "react";
import { Collapse, theme } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import StartFreeButton from "../buttons/StartFreeButton";

const CheckScopeIntro = () => {
  const { token } = theme.useToken();

  const panelStyle = {
    marginBottom: 0,
    background: "transparent",
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const FeaturesArray = [
    {
      question: "User-Friendly Interface",
      answer:
        "Easy-to-use interface designed specifically for office workers, making document management simple and efficient.",
    },
    {
      question: "Support for Multiple Formats",
      answer: "Works seamlessly with PDF and TIFF files, providing flexibility for different document types.",
    },
    {
      question: "Handles Handwritten Text",
      answer: "Accurately extracts handwritten numbers and text, ensuring no information is missed.",
    },
  ];

  const FeaturesArray2 = [
    {
      question: "Integration Ready",
      answer: "API available for developers to easily integrate into their existing workflows and applications.",
    },
    {
      question: "Secure",
      answer:
        "Ensures that your documents and data are kept secure and private, with robust security measures in place.",
    },
    {
      question: "Metadata Extraction",
      answer:
        "Extracts additional details like page numbers, tags, and more, to provide comprehensive document insights.",
    },
  ];

  const getFeaturesItems = (array, panelStyle) =>
    array.map((item, index) => ({
      key: `${index + 1}`,
      label: <p className="font-WantedSans font-medium text-base hover:text-blue-500 transition">{item.question}</p>,
      children: <p className="font-WantedSans text-blue-900 p-4 bg-blue-50 rounded text-sm">{item.answer}</p>,
      style: panelStyle,
    }));

  return (
    <div className="flex w-full items-center justify-center flex-col md:pt-24 py-12 text-white px-4">
      <div className="max-w-screen-xl w-full h-full flex flex-col md:items-center px-4 md:px-4 z-20 py-16 bg-gradient-to-b from-gray-600 to-gray-800 rounded-2xl">
        <h3 style={{ whiteSpace: "pre-wrap" }} className="text-3xl font-semibold text-center leading-normal">
          {`Focus on Building,\nNot Labelling and Training`}
        </h3>
        <h4 className="mt-4 mb-8 break-keep text-sm max-w-lg text-gray-400 leading-relaxed text-center w-full">
          Discover how our tool enhances your document handling with powerful features tailored to meet your needs.
        </h4>
        <div className="mb-12">
          <StartFreeButton mode="dark" />
        </div>

        <div className="max-w-3xl w-full flex flex-col items-center pb-12">
          <div className="w-full flex flex-col md:flex-row md:justify-center">
            <div className="w-full grid grid-cols-2 rounded-xl overflow-hidden">
              <Collapse
                bordered={false}
                size="middle"
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined style={{ color: "#fff" }} rotate={isActive ? 90 : 0} />
                )}
                style={{
                  background: "transparent",
                }}
                items={getFeaturesItems(FeaturesArray, panelStyle)}
                className="custom-collapse" // Add this class
                accordion
              />
              <Collapse
                bordered={false}
                size="middle"
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined style={{ color: "#fff" }} rotate={isActive ? 90 : 0} />
                )}
                style={{
                  background: "transparent",
                }}
                items={getFeaturesItems(FeaturesArray2, panelStyle)}
                className="custom-collapse" // Add this class
                accordion
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckScopeIntro;
