import React from "react";
import Signup from "./Signup";
import Login from "./Login";
import { Route, Routes } from "react-router-dom";
import SEOMetaTag from "../../utils/SEOMetaTag";

const Auth = () => {
  return (
    <div className="w-full flex flex-col items-center relative">
      <div
        style={{ minHeight: "calc(100vh - 10rem)" }}
        className="w-full flex flex-col items-center justify-center relative bg-neutral-50 flex-shrink-0 py-36"
      >
        <div className="z-20">
          <Routes>
            <Route
              path="/login"
              element={
                <>
                  <SEOMetaTag
                    title="Login - ParDocs"
                    description="Access your ParDocs account by logging in. Securely sign in to manage your document parsing tasks and access your account features."
                    keywords="ParDocs login, user login, document parsing account, secure login, access account"
                    url="https://www.pardocs.com/auth/login"
                  />
                  <Login />
                </>
              }
            />
            <Route
              path="/signup"
              element={
                <>
                  <SEOMetaTag
                    title="Sign Up - ParDocs"
                    description="Create a new ParDocs account to start automating document parsing and access powerful tools for managing your data. Sign up to get started."
                    keywords="ParDocs sign up, create account, document parsing tools, start with ParDocs, account registration"
                    url="https://www.pardocs.com/auth/signup"
                  />
                  <Signup />
                </>
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Auth;
