import { Input, Modal, Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import axios from "../../utils/authAxios";

const ChangeDocumentTypeModal = ({ setOpen, open, getExtractionData, currentDocumentType, extractionId }) => {
  const [isDocLoading, setDocLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [hasError] = useState(false);
  const [inputValue, setInputValue] = useState(currentDocumentType); // State to hold the generated API key

  const reset = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (!open) {
      setInputValue(currentDocumentType);
    }
    return () => {};
  }, [open, currentDocumentType]);

  const handleOk = () => {
    axios
      .patch(`/v1/templates/${extractionId}`, {
        document_type: inputValue,
      })
      .then((response) => {
        getExtractionData();
        openNotification();
        setOpen(false);
      })
      .catch((error) => {
        console.error("PUT EXTRACTIONS ERROR:", error);
      });
  };

  const handleCancel = () => {
    setDocLoading(false);
    setOpen(false);
  };

  // END:MODAL FUNCTIONS ======
  // START:UPLOAD FUNCTIONS ======
  const openNotification = () => {
    api.info({
      icon: <FaCircleCheck className="text-blue-500 w-6 h-6 opacity-90" />,
      message: <p className="text-base font-semibold text-gray-700">{"Changed Document Type"}</p>,
      description: <p className="text-sm text-gray-500 break-keep">{inputValue}</p>,
      placement: "top",
    });
  };

  return (
    <>
      <Modal
        open={open}
        title={
          <div className="font-WantedSans">
            <p className="text-lg">Change Document Type</p>
          </div>
        }
        closeIcon={null}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <button
            key={"close"}
            className={`border text-gray-600 px-4 py-2 h-10 rounded-lg transition font-medium font-WantedSans`}
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button
            key={"start"}
            onClick={handleOk}
            disabled={inputValue.trim().length === 0}
            className={`${
              inputValue.trim().length === 0 ? "bg-gray-300" : "bg-blue-600"
            }  text-white px-4 py-2 h-10 rounded-lg transition font-medium font-WantedSans ml-3`}
          >
            Change document type
          </button>,
        ]}
      >
        <div className="w-full h-full mt-4 mb-4 font-WantedSans">
          {hasError ? (
            <div className="py-8 bg-white w-full rounded-3xl bg-opacity-70 shadow-lg p-6 flex flex-col space-y-6 items-center justify-center h-96">
              <div className="flex flex-col items-center space-y-1 tracking-tight">
                <p className="text-gray-400">Couldn't read the files.</p>
                <p className="text-gray-400">Please try again later.</p>
              </div>
              <button
                onClick={reset}
                className="flex items-center space-x-2 text-blue-500 py-2 px-4 rounded-xl bg-gray-100 hover:text-blue-700 font-medium hover:bg-gray-200 transition"
              >
                <p className="text-sm flex-shrink-0">Retry</p>
              </button>
            </div>
          ) : isDocLoading ? (
            <div className="h-64 bg-white w-full rounded-3xl bg-opacity-70 p-6 flex flex-col space-y-6 items-center justify-center">
              <div className="flex flex-col items-center space-y-1 tracking-tight">
                <Spin size="large" className="pb-4" />
                <p className="text-gray-400">Please wait while we upload your document.</p>
              </div>
            </div>
          ) : (
            <div className="space-y-6 pb-6">
              <div className="bg-white rounded w-full">
                <div className="flex items-center space-x-2 mb-4">
                  <p className="text-sm text-gray-500">
                    Change document types that you want to extract, e.g., Proforma Invoice, Sales Contract, Lease
                    Agreement. Add them one by one.
                  </p>
                </div>
                <Input
                  placeholder="e.g. Sales Contract"
                  autoFocus
                  onChange={(e) => setInputValue(e.target.value)}
                  value={inputValue}
                  className="h-11"
                />
              </div>
            </div>
          )}
        </div>
        {contextHolder}
      </Modal>
    </>
  );
};

export default ChangeDocumentTypeModal;
