import React from "react";

const Partners = () => {
  const partners = [
    { src: "nkg", alt: "Partner 1" },
    { src: "waypoint", alt: "Partner 2" },
    { src: "aica", alt: "Partner 3" },
    { src: "volcafe", alt: "Partner 4" },
    { src: "snaac", alt: "Partner 5" },
    { src: "startuphub", alt: "Partner 6" },
    { src: "fintech", alt: "Partner 7" },
    { src: "ulsan", alt: "Partner 8" },
  ];

  return (
    <div className="w-full py-20 flex justify-center text-gray-700">
      <div className="max-w-screen-xl w-full h-full flex flex-col items-center justify-center px-4 md:px-16">
        <div className="text-gray-500 font-light text-lg text-center">
          <p className="inline">Ambitious teams of every size are building with ParDocs.</p>
        </div>

        <div
          style={{ filter: "grayscale(100%)", opacity: "0.8" }}
          className="mt-12 w-full text-center grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5"
        >
          {partners.map((item) => (
            <div key={item.alt}>
              <img
                src={`https://s3.pardocs.com/landing/partners/${item.src}.png`}
                alt={item.src}
                className="h-[6rem] w-36 object-contain object-center"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
