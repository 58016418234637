import { notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import axios from "../../utils/authAxios";
import { HttpStatusCode } from "axios";
import { VscLoading } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist";

const DropComponentAutoGenerate = ({
  setSelectedFiles,
  selectedFiles,
  multiple,
  setUploading,
  getExtractionData,
  setOpen,
  setSelectedTestFiles,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();
  const MAX_FILE_SIZE_MB = 40;
  const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to Bytes
  const MAX_PAGES = 20;

  const checkPdfPageCount = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument(arrayBuffer).promise;
    return pdf.numPages;
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      const uploadAndAutoGenerateTemplate = async (imageFile) => {
        // Extract the file extension and document name
        const extension = imageFile.name
          .substring(imageFile.name.lastIndexOf("."), imageFile.name.length)
          .toLowerCase();
        const documentName = imageFile.name.substring(0, imageFile.name.lastIndexOf(".")) + extension;

        const formData = new FormData();
        formData.append("file", imageFile);

        const endpointUrl = "/v1/documents";
        const response = await axios.post(`${endpointUrl}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          const documentId = response.data?.document_id;

          axios
            .post("/v1/templates/auto-generate", { document_id: documentId })
            .then((response) => {
              if (response.status === HttpStatusCode.Ok) {
                const templateId = response.data[0].template_id;

                navigate(`/templates?ex_id=${templateId}`);
                getExtractionData();
                setUploading(false);
                setOpen(false);
                setSelectedTestFiles(selectedFiles);
              }
            })
            .catch((e) => setSelectedFiles([]))
            .finally(() => setUploading(false));
        }

        // Check for successful response
        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the document name
        return {
          document_name: documentName,
        };
      };

      uploadAndAutoGenerateTemplate(selectedFiles[0]);
      setUploading(true);
    }

    return () => {};
  }, [getExtractionData, navigate, selectedFiles, setOpen, setSelectedFiles, setUploading, setSelectedTestFiles]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave?";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };
    if (selectedFiles.length > 0) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [selectedFiles]);

  const onDragEnter = useCallback(() => {
    setIsDragging(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles, fileRejections) => {
      setIsDragging(false);

      if (fileRejections.length > 0) {
        openNotificationWithIcon("error", "File Rejected", "Unsupported file type or multiple files.");
        return;
      }

      if (acceptedFiles.some((file) => file.size > MAX_FILE_SIZE)) {
        openNotificationWithIcon("error", "File Too Large", `Files must be smaller than ${MAX_FILE_SIZE_MB} MB.`);
        return;
      }

      // Page count check
      const pageCounts = await Promise.all(
        acceptedFiles.map(async (file) => {
          if (file.type === "application/pdf") {
            const numPages = await checkPdfPageCount(file);
            return numPages;
          }
          return 0;
        })
      );

      if (pageCounts.some((count) => count > MAX_PAGES)) {
        openNotificationWithIcon("error", "Too Many Pages", `Files must be less than ${MAX_PAGES} pages.`);
        return;
      }

      // Concatenate new files to the existing list
      setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
    [setSelectedFiles, MAX_FILE_SIZE]
  );

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDragEnter,
    onDragLeave,
    onDrop,
    multiple: multiple, // Disallow multiple file uploads
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      // "image/gif": [".gif"],
    },
  });

  return (
    <div className="w-full">
      {selectedFiles.length > 0 ? (
        <div className="flex flex-col items-start space-y-1">
          {selectedFiles.map((item, index) => (
            <div
              key={index}
              className="text-sm flex items-center rounded-lg p-3 w-full h-24 border relative shadow space-x-4"
            >
              <VscLoading className="animate-spin w-6 h-6 text-gray-500" />
              <div className="space-y-2">
                <p className="text-sm text-gray-500 text-left line-clamp-3 break-all">
                  Auto generating template... This takes about 1 minute.
                </p>
                <p className="text-xs text-sky-600 text-left line-clamp-3 break-all">{item.name}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`${
            isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300 hover:border-blue-500"
          } relative w-full h-48 rounded-lg border-dashed border flex items-center cursor-pointer transition group hover:bg-blue-50`}
        >
          <input {...getInputProps()} id="fileUpload" style={{ display: "none" }} />
          <div className="flex flex-col items-center w-full px-2 space-y-2">
            <FiUpload className="w-6 h-6 opacity-90 text-gray-400" />
            <div className="flex flex-col items-center text-xs text-gray-600 text-center">
              <p>Upload or drop your</p>
              <p style={{ whiteSpace: "pre-wrap" }} className="">
                <span className="text-blue-500 font-medium">document</span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropComponentAutoGenerate;
