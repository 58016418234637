import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./context/authContext";
import { ConfigProvider } from "antd";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <GoogleOAuthProvider clientId="506419402946-tkk8pjij6lsok9tq5km4kgu8cmlapp5n.apps.googleusercontent.com">
          <BrowserRouter>
            <ConfigProvider>
              <App />
            </ConfigProvider>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
);

const firebaseConfig = {
  apiKey: "AIzaSyClCrGKpKXwJxHTWB6uthmNHZ7rSh4zQtQ",
  authDomain: "pardocs-f5abe.firebaseapp.com",
  projectId: "pardocs-f5abe",
  storageBucket: "pardocs-f5abe.appspot.com",
  messagingSenderId: "896678368042",
  appId: "1:896678368042:web:33efb84dda4cd20b83c453",
  measurementId: "G-DB05JT5MYX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(analytics);
