import React, { useState } from "react";
import SubscriptionModal from "./modals/SubscriptionModal";

const PlanButton = ({ type }) => {
  const [isSubscriptionModalOpen, setSubscriptionModalOpen] = useState(false);

  return (
    <div className="h-14 w-full">
      <SubscriptionModal setOpen={setSubscriptionModalOpen} open={isSubscriptionModalOpen} />
      <div
        className={`${
          type === "white" ? "bg-white border" : "bg-gray-200"
        } w-full h-full rounded-lg flex items-center justify-between px-3`}
      >
        <div>
          <div className="text-xs text-gray-700">Free Tier</div>
          <div className="text-[0.65rem] text-gray-500">No-cost $0/month</div>
        </div>
        <button
          onClick={() => setSubscriptionModalOpen(true)}
          className={`${
            type === "white" ? "hover:bg-gray-100" : "hover:bg-gray-300"
          } text-xs py-1.5 px-3 rounded-lg font-medium transition text-gray-700 hover:text-blue-600`}
        >
          Upgrade
        </button>
      </div>
    </div>
  );
};

export default PlanButton;
