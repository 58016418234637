import React from "react";
import { Link, useLocation } from "react-router-dom";
import ProfileButton from "./ProfileButton";

const NavBar2 = () => {
  const location = useLocation(); // Hook to get the current path
  const currentPath = location.pathname;

  const NavButton = ({ path, title, newTab }) => {
    if (path)
      return (
        <Link
          to={path}
          target={newTab && "_blank"}
          rel={newTab && "noopener noreferrer"}
          className={`${
            currentPath.includes(path) ? "text-gray-700 font-semibold" : " text-gray-500 hover:text-gray-700"
          } text-sm hidden md:flex hover:bg-gray-100 px-3 py-1.5 rounded-full group font-WantedSans transition`}
        >
          <button className="flex items-center space-x-1.5">
            <p>{title}</p>
          </button>
        </Link>
      );
    else
      return (
        <button className="text-gray-700 text-sm hidden md:flex hover:bg-gray-100 px-3 py-1.5 rounded-full group font-WantedSans transition">
          {title}
        </button>
      );
  };

  return (
    <header className="w-full flex items-center justify-center z-30 h-12 bg-gray-100">
      <div className="flex items-center w-full h-full justify-between rounded-full px-3 py-4 lg:py-0">
        <Link to="/">
          <div className="flex items-center">
            <img
              src={"https://s3.pardocs.com/landing/pardocs_logo.png"}
              alt=""
              className="h-6 object-contain"
              draggable={false}
            />
          </div>
        </Link>
        <div className="flex flex-shrink-0 items-center text-gray-700">
          <div className="flex items-center space-x-3">
            <NavButton path={"/templates"} title={"Templates"} />
            <NavButton title={"API keys"} path="/api-keys/generate" />
            <NavButton path={"https://docs.pardocs.com/"} newTab title={"Docs"} />
            <ProfileButton />
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavBar2;
