import React, { useEffect } from "react";
import { useNavigate, Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Team from "./Dashboard/Team";
import APIKeys from "./Dashboard/APIKeys";
import NavBar2 from "../../components/NavBar2";

const Main = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === "/") {
      navigate("/templates");
    }
  }, [navigate]);

  return (
    <div style={{ height: "100svh" }} className="w-full">
      <NavBar2 />
      <div style={{ height: "calc(100svh - 3rem)" }} className="flex w-full">
        <Routes>
          <Route path="/api-keys/*" element={<APIKeys />} />
          <Route path="/templates/*" element={<Dashboard />} />
          <Route path="/team/*" element={<Team />} />
          {/* <Route path="/settings/*" element={<Settings />} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default Main;
