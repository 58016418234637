import React from "react";
import { IoBookOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import StartFreeButton from "../buttons/StartFreeButton";

const Welcome = () => {
  const supportedFormats = ["pdf", "docx", "xlsx", "jpg", "jpeg", "png"];
  return (
    <section
      style={{
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
      className="w-full flex justify-center relative"
    >
      <link rel="preload" href="https://s3.pardocs.com/landing/poster.png" as="image" />
      <div className="max-w-screen-xl w-full h-full flex flex-col md:items-center px-4 md:px-4 z-20 py-20 pt-12 md:pt-20 2xl:pt-24">
        <section className="w-full flex flex-col items-center text-gray-800 py-6">
          <h1
            style={{ whiteSpace: "pre-wrap" }}
            className="font-semibold text-4xl md:text-[2.8rem] 2xl:text-[3rem] leading-snug tracking-[-0.01em] text-center"
          >
            {`Document parser that automates\ndata extraction from pdfs, docx and more`}
          </h1>

          <h2 className="mt-8 md:mt-10 text-sm max-w-xl text-gray-500 leading-relaxed text-center">
            Transform complex and unstructured documents into clean JSON with advanced parsing solutions – Enhance efficiency and
            accuracy
          </h2>
          <div className="flex flex-row items-center space-x-3 md:space-x-4 mt-12 justify-center">
            <StartFreeButton />
            <Link to="https://docs.pardocs.com/" target="_blank" rel="noopener noreferrer">
              <button className="flex items-center justify-center space-x-3 h-9 px-3 md:px-5 rounded-full text-sm md:text-base border transition font-medium bg-white hover:bg-gray-100 text-gray-700">
                <IoBookOutline aria-hidden="true" />
                <span>Developer docs</span>
              </button>
            </Link>
          </div>
        </section>

        <section className="w-full mt-20 mb-4 font-medium">
          <p className="text-gray-500 text-xs mb-2">Supported Formats:</p>
          <div className="w-full flex flex-wrap items-center gap-2 font-medium">
            {supportedFormats.map((item, index) => (
              <div key={index} className="text-neutral-400 text-xs uppercase">
                {item}.
              </div>
            ))}
          </div>
        </section>

        <section className="w-full mb-4 flex flex-col items-center justify-center text-gray-700">
          <div className="w-full h-auto md:w-auto rounded-2xl overflow-hidden shadow-lg border">
            <video
              className="w-full h-auto"
              muted
              autoPlay
              loop
              playsInline
              controls={false}
              preload="metadata"
              loading="lazy"
              poster="https://s3.pardocs.com/landing/poster.png"
              aria-label="Demo video showing how to use ParDocs"
            >
              <source
                src="https://s3.pardocs.com/landing/pardocs_demo_aug_18_compressed.mov"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Welcome;
