import React from "react";
import { Link } from "react-router-dom";
import { VscThreeBars } from "react-icons/vsc";
import { Dropdown } from "antd";
import { FaDiscord } from "react-icons/fa";
import StartFreeButton from "./buttons/StartFreeButton";

const NavBar = () => {
  const items = [
    {
      key: "1",
      label: (
        <Link to={"/about"}>
          <button className="text-sm hover:text-blue-500 transition h-14 w-48">About</button>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to={"/pricing"}>
          <button className="text-sm hover:text-blue-500 transition h-14 w-48">Pricing</button>
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to={"/contact"}>
          <button className="text-sm hover:text-blue-500 transition h-14 w-48">Contact</button>
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link to={"https://docs.pardocs.com/"}>
          <button className="text-sm hover:text-blue-500 transition h-14 w-48">Developer Docs</button>
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link to={"https://discord.gg/xgEXkh7Rxk"}>
          <button className="text-sm hover:text-blue-500 transition h-14 w-48 flex space-x-1 items-center justify-center text-gray-500">
            <FaDiscord className="w-5 h-5" />
            <p>Community</p>
          </button>
        </Link>
      ),
    },
  ];

  const NavButton = ({ path, title, newTab }) => {
    return (
      <Link
        to={path}
        target={newTab ? "_blank" : ""}
        rel={newTab ? "noopener noreferrer" : ""}
        className="text-gray-700 text-sm hidden md:flex hover:bg-gray-100 px-3 py-1.5 rounded-full group font-WantedSans transition font-medium"
      >
        <button>{title}</button>
      </Link>
    );
  };

  return (
    <nav className="w-full flex-shrink-0 flex items-center justify-center z-30 fixed top-0 h-20 p-2 bg-white">
      <div className="flex items-center font-medium w-full max-w-screen-xl h-full justify-between rounded-full px-5 lg:px-10 py-4 lg:py-0">
        <Link to="/">
          <div className="flex items-center">
            <img
              src={"https://s3.pardocs.com/landing/pardocs_logo.png"}
              alt="ParDocs Logo"
              className="h-7 md:h-7 w-32 object-contain"
              draggable={false}
            />
          </div>
        </Link>
        <div className="flex flex-shrink-0 font-medium items-center text-gray-700">
          <ul className="flex items-center space-x-6 font-medium mr-6">
            <li>
              <NavButton path={"/about"} title={"About"} />
            </li>
            <li>
              <NavButton path={"/pricing"} title={"Pricing"} />
            </li>
            <li>
              <NavButton path={"/contact"} title={"Contact"} />
            </li>
            <li>
              <NavButton path={"https://docs.pardocs.com/"} newTab title={"Developer Docs"} />
            </li>
          </ul>
          <div className="hidden md:flex space-x-2 items-center font-medium">
            <Link className="flex-shrink-0" to="https://discord.gg/xgEXkh7Rxk">
              <button className="text-sm border h-9 px-3 md:px-5 rounded-full text-gray-500 transition flex-shrink-0 hover:bg-gray-100 flex items-center space-x-1">
                <FaDiscord className="w-5 h-5" />
                <p>Community</p>
              </button>
            </Link>
            <StartFreeButton />
          </div>
          <div className="md:hidden flex">
            <Dropdown menu={{ items }} placement="bottomRight">
              <button className="group md:hidden flex md:w-0 w-14 h-14 text-sm items-center justify-center">
                <VscThreeBars className="w-6 h-6 text-gray-700" />
              </button>
            </Dropdown>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
