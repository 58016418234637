import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AddDocumentTypeModal from "../modals/AddDocumentTypeModal";
import { BiPlus } from "react-icons/bi";
import { HiTemplate } from "react-icons/hi";
import PlanButton from "../PlanButton";

const SplitterSection = ({ extractorData, getExtractionData, setSelectedTestFiles }) => {
  const [isAddDocumentModalOpen, setAddDocumentModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (template_id) => {
    const params = new URLSearchParams(location.search);
    params.set("ex_id", template_id);
    navigate({ search: params.toString() });
  };

  const getClassNames = (template_id) => {
    const params = new URLSearchParams(location.search);
    return params.get("ex_id") === template_id
      ? "h-14 flex flex-col items-start justify-center px-3 text-xs text-blue-600 bg-gray-200 w-full rounded-lg"
      : "h-14 flex flex-col items-start justify-center px-3 text-xs text-gray-500 hover:bg-gray-200 w-full rounded-lg";
  };

  useEffect(() => {
    if (extractorData.length > 0) {
      const params = new URLSearchParams(location.search);
      if (!params.get("ex_id")) {
        params.set("ex_id", extractorData[0].template_id);
        navigate({ search: params.toString() });
      }
    }
  }, [extractorData, location.search, navigate]);

  return (
    <div className="w-52 flex-shrink-0 h-full relative">
      <AddDocumentTypeModal
        open={isAddDocumentModalOpen}
        setOpen={setAddDocumentModalOpen}
        getExtractionData={getExtractionData}
        setSelectedTestFiles={setSelectedTestFiles}
      />
      <div className="w-full h-full pr-2">
        <div className="uppercase text-xs text-gray-700 font-medium px-3 h-12 flex items-center space-x-2">
          <HiTemplate />
          <p>Templates</p>
        </div>
        <div className="w-full mb-2">
          <button
            onClick={() => setAddDocumentModalOpen(true)}
            className="text-white bg-blue-500 hover:bg-blue-400 text-sm font-medium border-none flex items-center justify-center rounded-lg transition space-x-1 py-2 px-3 flex-shrink-0 w-full"
          >
            <BiPlus className="w-5 h-5" />
            <p className="font-medium text-xs">Create Template</p>
          </button>
        </div>
        <div style={{ height: "calc(100svh - 12.8rem)" }} className="overflow-y-auto pb-3">
          {extractorData.length !== 0 &&
            extractorData.map((item) => (
              <button
                key={item.template_id}
                onClick={() => handleClick(item.template_id)}
                className={getClassNames(item.template_id)}
              >
                <p className="line-clamp-1 text-left">{item.document_type}</p>
                <p className="text-[0.65rem] text-gray-400">{item.template_id}</p>
              </button>
            ))}
        </div>
        <PlanButton />
      </div>
    </div>
  );
};

export default SplitterSection;
