import React from "react";
import { Link } from "react-router-dom";
import Partners from "../components/Landing/Partners";
import FAQ from "../components/Landing/FAQ";

const Pricing = () => {
  const Welcome = () => (
    <div className="w-full flex justify-center relative pt-4">
      <div className="max-w-screen-xl w-full h-full flex items-center px-4 md:px-16 z-20 text-center md:pb-16 pb-4">
        <div className="w-full flex flex-col justify-center items-center text-gray-700 break-keep">
          <h1 className="font-semibold text-4xl md:text-[3rem] 2xl:text-[3rem] leading-tight">
            Start Free, Pay As You Grow
          </h1>
          <h2 className="mt-8 md:mt-10 break-keep text-sm text-gray-500 leading-relaxed">
            Get 40 free pages each month. Only pay for what you use beyond that.
          </h2>
        </div>
      </div>
    </div>
  );

  const PricingCard = ({ type, title, subtitle, price, featureArray, isRecommended }) => {
    return (
      <div
        className={`${
          isRecommended ? "bg-blue-500 shadow-xl" : "bg-white bg-opacity-0"
        } p-2 rounded-lg space-y-2 flex flex-col items-center w-full`}
      >
        <p className="text-white font-medium text-sm h-5">{isRecommended ? "RECOMMENDED" : ""}</p>
        <div className={`${!isRecommended && "shadow-xl"} w-full rounded-lg border bg-white overflow-hidden`}>
          <div
            style={{ backgroundColor: "#F2F6F8" }}
            className="w-full h-52 flex flex-col justify-center items-center border-b px-4"
          >
            <p className={`text-gray-700 font-semibold text-3xl`}>{title}</p>
            <p className={`text-gray-700 mt-4 text-sm opacity-70 text-center`}>{subtitle}</p>
            {type === "custom" ? (
              <></>
            ) : (
              <div className={`text-gray-700 flex mt-4 items-center space-x-2`}>
                <div className="flex items-end space-x-1">
                  <span className={`text-4xl`}>{price}</span>
                  <span className="text-xl font-medium">USD</span>
                </div>
                <span className="text text-gray-400 font-normal">per user</span>
              </div>
            )}
          </div>
          <div style={{ height: "23rem" }} className="py-8 px-6 space-y-6">
            <p className="text-gray-500 py-1 text-sm">Get 40 free pages every month. Pay-as-you-go afterwards.</p>
            {featureArray?.map((item, index) => (
              <div key={index} className="flex items-center justify-between">
                <div>
                  <p className="text-gray-500 break-keep font-medium text-sm">{item.title}</p>
                  <p className="text-gray-500 break-keep text-xs">{item.subtitle}</p>
                </div>
                <p className="text-gray-500 break-keep text-sm">{item.value}</p>
              </div>
            ))}
          </div>

          <div className="w-full flex flex-col justify-end px-4 pb-4">
            <div className="h-14 w-full border rounded-lg overflow-hidden">
              {type === "basic" ? (
                <Link to={"/auth/signup"}>
                  <button className={`w-full h-full font-medium shadow bg-gray-700 text-white hover:bg-opacity-75`}>
                    Start for free
                  </button>
                </Link>
              ) : (
                <Link to={"/auth/signup"}>
                  <button className={`w-full h-full font-medium shadow bg-white text-gray-700 hover:bg-opacity-75`}>
                    Start for free
                  </button>
                </Link>
              )}
            </div>
            <div className="w-full flex justify-center">
              <p className="mt-4 text-xs text-gray-500">No credit card required</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full flex items-center flex-col relative pt-20 bg-neutral-50">
      <div className="w-full z-20 flex flex-col items-center">
        <div className="max-w-screen-xl w-full h-full flex flex-col md:items-center px-4 md:px-4 z-20 py-20 pt-12 md:pt-20 2xl:pt-24">
          <Welcome />

          <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-3 w-full mb-16">
            <PricingCard
              type="free"
              bgColor="#F2F6F8"
              title="Free Plan"
              subtitle="Start with limited access to each API and streamline your development process."
              price={"$0"}
              featureArray={[{ title: "40 pages a month", value: "Free" }]}
            />
            <PricingCard
              type="basic"
              bgColor="#4EA660"
              title="Basic Plan"
              subtitle="Access each API individually to streamline and enhance your development process."
              price={"$9"}
              featureArray={[
                { title: "40 pages a month", value: "Free" },
                { title: "Additional 150 pages a month", value: "$9 / month" },
              ]}
              isRecommended
            />
            <PricingCard
              type="custom"
              bgColor="#1E6AD9"
              title="Pay-as-you-go"
              subtitle="Simplify your workflow with our All-in-One API for efficient deployment and optimization."
              price={"Custom"}
              featureArray={[
                { title: "Document Upload", value: "$0.001 / page" },
                { title: "Document Splitter", value: "$0.012 / page" },
                { title: "Document Extractor", value: "$0.071 / page" },
                {
                  title: "All-in-One",
                  subtitle: "Upload + Splitter + Extractor",
                  value: "$0.069 / page",
                },
              ]}
            />
          </div>
          <Partners />
          <FAQ />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
