import React, { useState, useEffect } from "react";
import { FaList } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Segmented } from "antd";
import ExtractorSectionCode from "./ExtractorSectionCode";
import ExtractorSectionUi from "./ExtractorSectionUi";
import { IoCodeSlash } from "react-icons/io5";

const ExtractorSection = ({ extractorData, getExtractionData }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const extractionId = params.get("ex_id");
  const [currentData, setCurrentData] = useState({});
  const [currentTab, setCurrentTab] = useState("ui");

  useEffect(() => {
    if (extractionId && extractorData.length > 0) {
      const extraction = extractorData.find((item) => item.template_id === extractionId);
      if (extraction) {
        setCurrentData(extraction);
      }
    }
  }, [extractionId, extractorData]);

  return (
    <div className="w-full h-full">
      <div className="w-full h-full bg-white flex flex-col">
        <div className="px-3 flex h-12 items-center justify-between border-b">
          <div className="flex items-center space-x-2">
            <p>{currentData.document_type}</p>
            <p className="text-xs text-gray-500">({currentData.template_id})</p>
          </div>
          <div className="flex items-center space-x-1">
            <div className="text-gray-500 text-xs px-4 flex items-center">
              {currentData?.template_updated_at && (
                <p>Last saved at {moment(currentData.template_updated_at).format("h:mm A, MMM DD, YYYY")}</p>
              )}
            </div>
            <Segmented
              options={[
                {
                  label: "",
                  value: "ui",
                  icon: <FaList className="mt-1.5 w-3.5 h-3.5" />,
                },
                {
                  label: "",
                  value: "code",
                  icon: <IoCodeSlash className="mt-1.5 w-4 h-4" />,
                },
              ]}
              onChange={(value) => {
                setCurrentTab(value);
              }}
            />
          </div>
        </div>

        <div className="h-full px-3">
          <div className="my-3 text-xs font-medium text-gray-500">
            Add fields to your schema
          </div>
          {currentTab === "ui" ? (
            <ExtractorSectionUi extractorData={extractorData} getExtractionData={getExtractionData} />
          ) : (
            <ExtractorSectionCode extractorData={extractorData} getExtractionData={getExtractionData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ExtractorSection;
