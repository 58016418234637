import React from "react";
import { Collapse } from "antd";
import { Link } from "react-router-dom";

const FAQ = () => {
  const FAQArray = [
    {
      question: "How do I start using ParDocs?",
      answer:
        "You can start using ParDocs for free by signing up on our website. No credit card is required to get started.",
    },
    {
      question: "What types of documents can ParDocs handle?",
      answer:
        "ParDocs supports a variety of document formats, including PDF, DOCX, XLSX for documents, and JPG, JPEG, PNG for images. This makes it versatile and capable of handling most common document types.",
    },
    {
      question: "Do I need to label data or train the model before using ParDocs?",
      answer:
        "No, ParDocs is designed to work out-of-the-box, meaning it does not require any pre-training or data labeling. You can start extracting data immediately without any preparatory steps.",
    },
    {
      question: "Can ParDocs extract tables from documents?",
      answer:
        "Yes, ParDocs can extract tables (arrays) from documents, in addition to other data types such as Strings, Dates, Booleans, and Groups. This feature ensures comprehensive data extraction capabilities.",
    },
    {
      question: "Can ParDocs extract images from documents?",
      answer:
        "Yes, ParDocs can extract images from documents, including PDFs and other supported formats. This feature allows you to retrieve embedded images for further analysis or use in other applications, enhancing the versatility of the tool.",
    },
    {
      question: "Can ParDocs handle multiple document types in a single file upload?",
      answer:
        "Yes, ParDocs can handle multiple document types in a single file upload. It efficiently processes and extracts data from various document formats uploaded together, making it convenient for users dealing with diverse document types.",
    },
    {
      question: "How reliable is ParDocs?",
      answer:
        "ParDocs is highly reliable and accurate, trusted by government entities for its data extraction capabilities. Its robust performance ensures that your data extraction needs are met with high precision.",
    },
    {
      question: "What are the pricing plans for ParDocs?",
      answer:
        "ParDocs offers a free plan with 40 free pages every month. The Basic Plan costs $9 per user per month and includes an additional 150 pages. There are also pay-as-you-go options for document upload, splitting, and extraction to suit different usage needs.",
    },
    {
      question: "How do I test my model in ParDocs?",
      answer:
        "After setting up your document types and schema, you can easily test your model. Once satisfied with the results, create an API key and integrate it into your project to start using ParDocs in your workflow.",
    },
  ];

  return (
    <div className="w-full flex justify-center relative bg-gray-50">
      <div className="max-w-screen-xl w-full h-full flex flex-col items-center px-4 z-20 text-center py-20">
        <h3 style={{ whiteSpace: "pre-wrap" }} className="text-4xl font-semibold text-center leading-normal">
          Frequently Asked Questions
        </h3>
        <div className="mt-4 mb-8 break-keep text-base max-w-lg text-gray-500 leading-relaxed text-center w-full">
          <p className="inline text-gray-500">Need more information?</p>
          <Link to="/contact-us">
            <p className="inline text-blue-500 ml-2">Contact us</p>
          </Link>
        </div>

        <div className="w-full space-y-2 max-w-screen-lg">
          {FAQArray.map((item, index) => (
            <Collapse
              key={index}
              size="middle"
              className="bg-white text-left text-gray-700"
              items={[
                {
                  key: index,
                  label: <p className="font-semibold">{item.question}</p>,
                  children: <p className="break-keep">{item.answer}</p>,
                },
              ]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
