import React from "react";
import ApiKeysLeftPanel from "../../../components/ApiKeys/ApiKeysLeftPanel";
import { Route, Routes } from "react-router-dom";
import Usage from "../../../components/ApiKeys/Usage";
import Generate from "../../../components/ApiKeys/Generate";

const APIKeys = () => {
  return (
    <div className="w-full h-full bg-gray-100 flex px-2 pb-2">
      <ApiKeysLeftPanel />
      <div className="bg-white rounded-lg w-full overflow-hidden border">
        <Routes>
          <Route path="/generate/*" element={<Generate />} />
          <Route path="/usage/*" element={<Usage />} />
        </Routes>
      </div>
    </div>
  );
};

export default APIKeys;
