import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { PiStarFourFill } from "react-icons/pi";
import DropComponentAutoGenerate from "../dropComponent/DropComponentAutoGenerate";
import { VscLoading } from "react-icons/vsc";

const AddDocumentTypeModal = ({ setOpen, open, getExtractionData, setSelectedTestFiles }) => {
  const [isDocLoading, setDocLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setUploading] = useState(false);

  const [hasError] = useState(false);

  const reset = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (!open) {
      setSelectedFiles([]);
    }
    return () => {};
  }, [open]);

  const handleCancel = () => {
    setDocLoading(false);
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        title={
          <div className="font-WantedSans">
            <PiStarFourFill className="inline text-sky-500" />{" "}
            <p className="text-lg inline">Auto-generate your template</p>
          </div>
        }
        maskClosable={false}
        closeIcon={null}
        onCancel={handleCancel}
        centered
        footer={[
          <button
            key={"close"}
            className={`border text-gray-600 px-4 py-2 h-10 rounded-lg transition font-medium font-WantedSans`}
            onClick={handleCancel}
            disabled={isUploading}
          >
            Cancel
          </button>,
          <button
            key={"start"}
            className={`${
              isUploading ? "bg-blue-300" : "bg-gray-300"
            }  text-white px-4 py-2 h-10 rounded-lg transition font-medium font-WantedSans ml-3`}
          >
            {isUploading ? (
              <div className="flex space-x-2">
                <VscLoading className="animate-spin w-5 h-5 text-white" />
                <p>Auto generating template...</p>
              </div>
            ) : (
              <p>Create Template</p>
            )}
          </button>,
        ]}
      >
        <div className="w-full h-full mt-4 mb-4 font-WantedSans">
          {hasError ? (
            <div className="py-8 bg-white w-full rounded-3xl bg-opacity-70 shadow-lg p-6 flex flex-col space-y-6 items-center justify-center h-96">
              <div className="flex flex-col items-center space-y-1 tracking-tight">
                <p className="text-gray-400">Couldn't read the files.</p>
                <p className="text-gray-400">Please try again later.</p>
              </div>
              <button
                onClick={reset}
                className="flex items-center space-x-2 text-blue-500 py-2 px-4 rounded-xl bg-gray-100 hover:text-blue-700 font-medium hover:bg-gray-200 transition"
              >
                <p className="text-sm flex-shrink-0">Retry</p>
              </button>
            </div>
          ) : isDocLoading ? (
            <div className="h-64 bg-white w-full rounded-3xl bg-opacity-70 p-6 flex flex-col space-y-6 items-center justify-center">
              <div className="flex flex-col items-center space-y-1 tracking-tight">
                <Spin size="large" className="pb-4" />
                <p className="text-gray-400">Please wait while we upload your document.</p>
              </div>
            </div>
          ) : (
            <div className="bg-white rounded w-full">
              <div className="my-4 space-y-3">
                <p className="text-sm text-gray-500">We'll create a template schema to help you get started quickly.</p>
                <div className="space-y-1">
                  <p className="text-sm text-gray-700 font-medium">Free tier supports:</p>
                  <p className="text-sm text-gray-500">
                    • Documents <span className="font-semibold bg-yellow-100 text-gray-700">less than 20 pages</span>.
                  </p>
                  <p className="text-sm text-gray-500">
                    • Documents <span className="font-semibold bg-yellow-100 text-gray-700">smaller than 40 MB</span>.
                  </p>
                </div>
              </div>

              <DropComponentAutoGenerate
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                isUploading={isUploading}
                setUploading={setUploading}
                getExtractionData={getExtractionData}
                setOpen={setOpen}
                setSelectedTestFiles={setSelectedTestFiles}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AddDocumentTypeModal;
