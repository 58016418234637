import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "../../utils/authAxios";
import { Button, Input, Select, Checkbox } from "antd";
import { FiTrash } from "react-icons/fi";
import { CiViewTable } from "react-icons/ci";
import { VscJson } from "react-icons/vsc";
import { TbRadiusBottomLeft } from "react-icons/tb";

const ExtractorSectionUi = ({ extractorData, getExtractionData }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const extractionId = params.get("ex_id");
  const [currentData, setCurrentData] = useState({});
  const [keyValuePairs, setKeyValuePairs] = useState([]);
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("string");
  const [nestedKeyValues, setNestedKeyValues] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (extractionId && extractorData.length > 0) {
      const reverseTransformJsonToKeyValuePairs = (jsonData) => {
        if (typeof jsonData !== "object" || jsonData === null) {
          console.warn("jsonData is not an object:", jsonData);
          return [];
        }

        const result = [];

        for (const [key, value] of Object.entries(jsonData)) {
          if (Array.isArray(value)) {
            // Handle table (array of objects)
            const nestedPairs = value
              .map((item, index) => {
                if (typeof item === "object" && item !== null) {
                  return Object.entries(item).map(([nestedKey, nestedValue]) => ({
                    key: nestedKey,
                    value: getTypeFromValue(nestedValue),
                  }));
                } else {
                  console.warn(`Invalid item at index ${index} in array for field "${key}":`, item);
                  return [];
                }
              })
              .flat();

            result.push({
              key: key,
              value: "table",
              nested: nestedPairs,
            });
          } else if (typeof value === "object" && value !== null) {
            // Handle nested object
            const nestedPairs = Object.entries(value).map(([nestedKey, nestedValue]) => ({
              key: nestedKey,
              value: getTypeFromValue(nestedValue),
            }));

            result.push({
              key: key,
              value: "object",
              nested: nestedPairs,
            });
          } else {
            // Handle primitive types
            result.push({
              key: key,
              value: getTypeFromValue(value),
              nested: [],
            });
          }
        }

        return result;
      };

      const getTypeFromValue = (value) => {
        if (value === null || value === undefined) return "string"; // Default to string for null/undefined
        if (value === "string") return "string";
        if (value === "number") return "number";
        if (value === "date") return "date";
        if (value === "boolean") return "boolean";
        if (Array.isArray(value)) return "table";
        if (typeof value === "object") return "object";
        return "string"; // Default case
      };

      const extraction = extractorData.find((item) => item.template_id === extractionId);
      if (extraction) {
        setCurrentData(extraction);
        setKeyValuePairs(reverseTransformJsonToKeyValuePairs(extraction.template_data));
      }
    }
  }, [extractionId, extractorData]);

  const handleSave = (data) => {
    const jsonCode = transformKeyValuePairs(data);
    axios
      .patch(`/v1/templates/${extractionId}`, {
        document_type: currentData.document_type,
        template_data: jsonCode,
      })
      .then((response) => {
        getExtractionData();
        setNewKey("");
        setNewValue("string");
      })
      .catch((error) => {
        console.error("PUT EXTRACTIONS ERROR:", error);
      });
  };

  const transformKeyValuePairs = (keyValuePairs) => {
    console.log("Input keyValuePairs:", keyValuePairs);

    // Ensure keyValuePairs is an array
    if (!Array.isArray(keyValuePairs)) {
      console.warn("keyValuePairs is not an array:", keyValuePairs);
      return { LOL: "LOL" };
    }

    return keyValuePairs.reduce((result, item) => {
      if (item && typeof item === "object") {
        if (item.value === "table" && Array.isArray(item.nested) && item.nested.length > 0) {
          // Handle table (array of objects)
          result[item.key] = [
            item.nested.reduce((acc, nestedItem) => {
              if (nestedItem && typeof nestedItem === "object" && "key" in nestedItem && "value" in nestedItem) {
                acc[nestedItem.key] = getDefaultValueForType(nestedItem.value);
              } else {
                console.warn("Invalid nested item:", nestedItem);
              }
              return acc;
            }, {}),
          ];
        } else if (item.value === "object" && Array.isArray(item.nested) && item.nested.length > 0) {
          // Handle nested object
          result[item.key] = item.nested.reduce((acc, nestedItem) => {
            if (nestedItem && typeof nestedItem === "object" && "key" in nestedItem && "value" in nestedItem) {
              acc[nestedItem.key] = getDefaultValueForType(nestedItem.value);
            } else {
              console.warn("Invalid nested item:", nestedItem);
            }
            return acc;
          }, {});
        } else {
          result[item.key] = getDefaultValueForType(item.value);
        }
      }

      return result;
    }, {});
  };

  const getDefaultValueForType = (type) => {
    switch (type) {
      case "string":
        return "string";
      case "boolean":
        return "boolean";
      case "number":
        return "number";
      case "date":
        return "date"; // or you could use a default date string like "YYYY-MM-DD"
      case "table":
        return []; // empty array for table type without nested items
      case "object":
        return {}; // empty object for object type without nested items
      default:
        return null;
    }
  };

  const handleAddKeyValue = () => {
    setKeyValuePairs([...keyValuePairs, { key: newKey, value: newValue, nested: [] }]);
    handleSave([...keyValuePairs, { key: newKey, value: newValue, nested: [] }]);
  };

  const handleNestedInputChange = (parentIndex, field, value) => {
    setNestedKeyValues((prev) => ({
      ...prev,
      [parentIndex]: {
        ...(prev[parentIndex] || {}),
        [field]: value,
      },
    }));
  };

  // Handle adding nested key-value pairs
  const handleAddNestedKeyValue = (parentIndex) => {
    const nestedInputs = nestedKeyValues[parentIndex] || { newNestedKey: "", newNestedValue: "string" };
    const updatedKeyValuePairs = keyValuePairs.map((pair, index) => {
      if (index === parentIndex) {
        return {
          ...pair,
          nested: [...(pair.nested || []), { key: nestedInputs.newNestedKey, value: nestedInputs.newNestedValue }],
        };
      }
      return pair;
    });
    setKeyValuePairs(updatedKeyValuePairs);
    handleSave(updatedKeyValuePairs);
    // Clear nested inputs for the current parent
    setNestedKeyValues((prev) => ({
      ...prev,
      [parentIndex]: {
        newNestedKey: "",
        newNestedValue: "string",
      },
    }));
  };

  const handleTypeChange = (value) => {
    setNewValue(value.value);
  };

  const handleCheckboxChange = (index, nestedIndex = null) => {
    setSelectedRows((prevSelected) => {
      const newSelected = [...prevSelected];
      const key = nestedIndex !== null ? `${index}-${nestedIndex}` : index.toString();

      if (newSelected.includes(key)) {
        return newSelected.filter((item) => item !== key);
      } else {
        return [...newSelected, key];
      }
    });
  };

  const isRowSelected = (index, nestedIndex = null) => {
    const key = nestedIndex !== null ? `${index}-${nestedIndex}` : index.toString();
    return selectedRows.includes(key);
  };

  const handleDeleteSelectedRows = () => {
    const newKeyValuePairs = keyValuePairs.filter((pair, index) => {
      if (!isRowSelected(index)) {
        if (pair.nested) {
          pair.nested = pair.nested.filter((_, nestedIndex) => !isRowSelected(index, nestedIndex));
        }
        return true;
      }
      return false;
    });
    setKeyValuePairs(newKeyValuePairs);
    handleSave(newKeyValuePairs);
    setSelectedRows([]);
  };

  const isAllSelected = () => {
    const totalRows = keyValuePairs.reduce((total, pair) => {
      return total + 1 + (pair.nested ? pair.nested.length : 0);
    }, 0);
    return selectedRows.length === totalRows;
  };

  const handleSelectAll = () => {
    if (isAllSelected()) {
      setSelectedRows([]);
    } else {
      const allRows = keyValuePairs.flatMap((pair, index) => {
        const rows = [index.toString()];
        if (pair.nested) {
          rows.push(...pair.nested.map((_, nestedIndex) => `${index}-${nestedIndex}`));
        }
        return rows;
      });
      setSelectedRows(allRows);
    }
  };

  return (
    <div className="w-full">
      <div className="mb-3 space-x-2 flex rounded-lg">
        <Input
          placeholder="Field name"
          value={newKey}
          onChange={(e) => setNewKey(e.target.value)}
          style={{ padding: "0 8px", borderRadius: "4px", height: "32px" }}
          className="w-40"
        />

        <Select
          value={{ value: newValue, label: newValue.charAt(0).toUpperCase() + newValue.slice(1) }} // Display the current value
          labelInValue
          defaultValue={{ value: "string", label: "string" }}
          style={{ width: 100 }}
          className="text-xs"
          rootClassName="text-xs"
          popupClassName="text-xs"
          onChange={handleTypeChange}
          options={[
            {
              value: "string",
              label: "String",
            },
            {
              value: "boolean",
              label: "Boolean",
            },
            {
              value: "number",
              label: "Number",
            },
            {
              value: "date",
              label: "Date",
            },
            {
              value: "table",
              label: (
                <div className="flex items-center space-x-1">
                  <CiViewTable />
                  <p>Table</p>
                </div>
              ),
            },
            {
              value: "object",
              label: (
                <div className="flex items-center space-x-1">
                  <VscJson />
                  <p>Object</p>
                </div>
              ),
            },
          ]}
        />
        <button
          onClick={handleAddKeyValue}
          disabled={!newKey?.trim() || !newValue?.trim()} // Disable when input is empty
          className={`${
            !newKey?.trim() || !newValue?.trim() ? "bg-gray-100 text-gray-400" : "bg-blue-500 text-white cursor-pointer"
          } text-xs rounded px-3 space-x-2 h-8 flex items-center justify-center transition border`}
        >
          <p>Add field</p>
        </button>
        <button
          onClick={handleDeleteSelectedRows}
          disabled={selectedRows.length === 0}
          className={`${
            selectedRows.length !== 0
              ? "text-red-600 border-red-400 hover:bg-red-50 bg-white cursor-pointer"
              : "bg-gray-100 text-gray-400"
          } text-xs rounded px-3 space-x-2 h-8 flex items-center justify-center transition border`}
        >
          <FiTrash className="w-4 h-4" />
          <p>Remove selected fields</p>
        </button>
      </div>

      <div className="px-3 py-2 text-xs flex min-w-48 items-center space-x-3 font-medium bg-gray-100 rounded-lg">
        <div className="pl-1 flex">
          <Checkbox checked={isAllSelected()} onChange={handleSelectAll} />
        </div>
        <div className="w-48 h-full text-gray-500">Field name</div>
        <div className="text-gray-500 h-full w-28">Field type</div>
      </div>
      {keyValuePairs.length === 0 ? (
        <div className="bg-gray-200 rounded-lg px-3 py-2 text-xs flex min-w-48 items-center space-x-3">
          <div className="w-48 border-r h-full text-gray-400">e.g. issuedDate</div>
          <div className="text-gray-400 h-full w-28 border-r">Date</div>
        </div>
      ) : (
        <div style={{ height: "calc(100svh - 15rem)" }} className="flex flex-col overflow-y-auto divide-y">
          {keyValuePairs.map((pair, index) => (
            <div key={index}>
              <div className="bg-white rounded-lg px-3 py-2 text-xs flex min-w-48 items-center space-x-3 h-10">
                <Checkbox className="w-6" checked={isRowSelected(index)} onChange={() => handleCheckboxChange(index)} />
                <div className="w-52 border-r font-medium h-full text-sky-600 flex items-center truncate">
                  {pair.key}
                </div>
                <div className="text-gray-500 h-full w-16 flex items-center">{pair.value}</div>
                {(pair.value === "table" || pair.value === "object") && (
                  <div className="flex space-x-1 text-xs">
                    <Input
                      placeholder="Nested Field"
                      value={(nestedKeyValues[index] && nestedKeyValues[index].newNestedKey) || ""}
                      onChange={(e) => handleNestedInputChange(index, "newNestedKey", e.target.value)}
                      className="w-32 h-7 text-xs"
                    />
                    <Select
                      value={{
                        value: (nestedKeyValues[index] && nestedKeyValues[index].newNestedValue) || "string",
                        label:
                          ((nestedKeyValues[index] && nestedKeyValues[index].newNestedValue) || "string")
                            .charAt(0)
                            .toUpperCase() +
                          ((nestedKeyValues[index] && nestedKeyValues[index].newNestedValue) || "string").slice(1),
                      }}
                      labelInValue
                      className="h-7 text-xs"
                      defaultValue={{ value: "string", label: "String" }}
                      style={{ width: 95, fontSize: "0.75rem" }} // Ensuring xs text size with inline style
                      onChange={(value) => handleNestedInputChange(index, "newNestedValue", value.value)}
                      options={[
                        { value: "string", label: "String" },
                        { value: "boolean", label: "Boolean" },
                        { value: "number", label: "Number" },
                        { value: "date", label: "Date" },
                      ]}
                    />
                    <Button
                      onClick={() => handleAddNestedKeyValue(index)}
                      type="primary"
                      className="text-xs px-3 h-7"
                      disabled={!nestedKeyValues[index]?.newNestedKey?.trim()}
                    >
                      Add field
                    </Button>
                  </div>
                )}
              </div>
              {(pair.value === "table" || pair.value === "object") && pair.nested.length > 0 && (
                <div className="w-full">
                  {pair.nested &&
                    pair.nested.map((nestedPair, nestedIndex) => (
                      <div key={nestedIndex} className="flex items-center">
                        <div className="w-6 h-10 flex-shrink-0 flex flex-col items-end rounded-l-lg justify-center text-sky-600 opacity-50">
                          <TbRadiusBottomLeft />
                        </div>
                        <div
                          key={nestedIndex}
                          className="bg-white rounded-lg px-3 py-2 text-xs flex min-w-48 items-center space-x-3 w-full h-10"
                        >
                          <Checkbox
                            className="w-6"
                            checked={isRowSelected(index, nestedIndex)}
                            onChange={() => handleCheckboxChange(index, nestedIndex)}
                          />
                          <div className="w-[11.5rem] border-r font-medium h-full text-sky-600 flex items-center truncate">
                            {nestedPair.key}
                          </div>
                          <div className="text-gray-500 h-full w-28 flex items-center">{nestedPair.value}</div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ExtractorSectionUi;
