import React from "react";
import { Modal, Button, notification } from "antd";
import { FaCopy } from "react-icons/fa";

const SaveKeyModal = ({ open, onClose, apiKey }) => {
  const [api, contextHolder] = notification.useNotification();

  // END:MODAL FUNCTIONS ======
  // START:UPLOAD FUNCTIONS ======
  const openNotification = () => {
    api.info({
      icon: <FaCopy className="text-blue-500 w-5 h-5 opacity-90" />,
      message: <p className="text-base font-semibold text-gray-700">{"Copied secret key"}</p>,
      description: null,
      placement: "topRight",
    });
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(apiKey);
    openNotification();
  };

  return (
    <Modal
      title="Save your key"
      open={open}
      onCancel={onClose}
      centered
      footer={[
        <Button key="done" type="primary" onClick={onClose}>
          Done
        </Button>,
      ]}
    >
      {contextHolder}
      <div className="space-y-4">
        <p>
          Store this secret key securely, as you won't be able to view it again through your ParDocs account. If you
          lose it, you'll need to generate a new one.
        </p>
        <div className="bg-gray-100 px-4 py-2 rounded flex justify-between items-center">
          <span className="text-gray-600">{apiKey}</span>
          <button
            onClick={handleCopy}
            className="w-9 h-9 rounded-full flex items-center justify-center hover:bg-gray-300 bg-gray-200 text-gray-400 hover:text-blue-500 transition"
          >
            <FaCopy className="" />
          </button>
        </div>
        <p className="text-sm text-gray-500">Permissions: Read and write API resources</p>
      </div>
    </Modal>
  );
};

export default SaveKeyModal;
