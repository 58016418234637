import React, { useContext } from "react";
import { Avatar, Table } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../context/authContext";

const Members = () => {
  const { userState } = useContext(AuthContext);
  const userInfo = userState.user;

  const data = [
    {
      key: "1",
      name: userInfo.userName,
      email: userInfo.userEmail,
      role: "OWNER",
    },
    // Add more data here...
  ];

  const columns = [
    {
      title: "Member",
      key: "member",
      render: (text, record) => (
        <div className="flex space-x-4 items-center">
          {userInfo.userId === 32 || userInfo.userId === 33 ? (
            <img src={record.profile} className="w-10 h-10 rounded-full object-cover" alt="" />
          ) : (
            <Avatar icon={<UserOutlined />} />
          )}

          <div className="space-y-1">
            <p className="text-sm font-semibold">{record.name}</p>
            <p className="text-xs text-gray-500">{record.email}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role) => <span className="text-gray-500 text-xs tracking-wider font-medium">{role}</span>,
    },
  ];

  return (
    <div className="">
      <div className="flex justify-between mb-4">
        <div className="space-y-1">
          <h1 className="text-xl font-medium mb-2">{data.length} Workspace Team Member</h1>
          <p className="text-sm text-gray-400">{data.length} of UNLIMITED seats are currently in use.</p>
        </div>
      </div>

      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        showHeader={true}
        bordered={false}
        rootClassName="border rounded-lg bg-gray-100 overflow-hidden"
      />
    </div>
  );
};

export default Members;
