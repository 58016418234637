import { Input, Modal, Spin, notification } from "antd";
import React, { useState } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import SaveKeyModal from "./SaveKeyModal"; // Import the new modal
import axios from "../../utils/authAxios";

const CreateAPIKeyModal = ({ setOpen, open, getExistingApi }) => {
  const [isDocLoading, setDocLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [hasError] = useState(false);
  const [showSaveKeyModal, setShowSaveKeyModal] = useState(false); // State to control SaveKeyModal
  const [generatedApiKey, setGeneratedApiKey] = useState(""); // State to hold the generated API key

  const reset = () => {
    window.location.reload();
  };

  const handleOk = () => {
    setOpen(false);
  };

  const requestAPIKey = () => {
    setDocLoading(true); // Indicate the start of the upload process
    axios
      .post(`/v1/auth/api_keys`)
      .then((response) => {
        const requestedKey = response.data.api_key;

        if (requestedKey) {
          setDocLoading(false);
          openNotification();
          setOpen(false);
          setGeneratedApiKey(requestedKey);
          setShowSaveKeyModal(true);
          getExistingApi();
        }
      })
      .catch((e) => console.log("REQUEST API KEY ERROR: ", e));
  };

  const handleCancel = () => {
    setDocLoading(false);
    setOpen(false);
  };

  // END:MODAL FUNCTIONS ======
  // START:UPLOAD FUNCTIONS ======
  const openNotification = () => {
    api.info({
      icon: <FaCircleCheck className="text-blue-500 w-6 h-6 opacity-90" />,
      message: <p className="text-base font-semibold text-gray-700">{"Secret key created"}</p>,
      description: <p className="text-sm text-gray-500 break-keep">{"Check the docs to get started"}</p>,
      placement: "top",
    });
  };

  return (
    <>
      <Modal
        open={open}
        title={
          <div className="font-WantedSans">
            <p className="text-lg">Create new secret key</p>
          </div>
        }
        closeIcon={null}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <button
            key={"close"}
            className={`border text-gray-600 px-4 py-2 h-10 rounded-lg transition font-medium font-WantedSans`}
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button
            key={"start"}
            className={`bg-blue-600 text-white px-4 py-2 h-10 rounded-lg transition font-medium font-WantedSans ml-3`}
            onClick={() => requestAPIKey()}
          >
            Create secret key
          </button>,
        ]}
      >
        <div className="w-full h-full mt-4 mb-4 font-WantedSans">
          {hasError ? (
            <div className="py-8 bg-white w-full rounded-3xl bg-opacity-70 shadow-lg p-6 flex flex-col space-y-6 items-center justify-center h-96">
              <div className="flex flex-col items-center space-y-1 tracking-tight">
                <p className="text-gray-400">Couldn't read the files.</p>
                <p className="text-gray-400">Please try again later.</p>
              </div>
              <button
                onClick={reset}
                className="flex items-center space-x-2 text-blue-500 py-2 px-4 rounded-xl bg-gray-100 hover:text-blue-700 font-medium hover:bg-gray-200 transition"
              >
                <p className="text-sm flex-shrink-0">Retry</p>
              </button>
            </div>
          ) : isDocLoading ? (
            <div className="h-64 bg-white w-full rounded-3xl bg-opacity-70 p-6 flex flex-col space-y-6 items-center justify-center">
              <div className="flex flex-col items-center space-y-1 tracking-tight">
                <Spin size="large" className="pb-4" />
                <p className="text-gray-400">Please wait while we upload your document.</p>
              </div>
            </div>
          ) : (
            <div className="space-y-6 pb-6">
              <div className="bg-white rounded w-full">
                <div className="flex items-center space-x-2 mb-2">
                  <p className="font-medium">Name</p>
                  <p className="text-xs text-gray-400">- Edit name coming soon</p>
                </div>
                <Input placeholder="My Test Key" disabled autoFocus value={"My Test Key"} className="h-11" />
              </div>
            </div>
          )}
        </div>
        {contextHolder}
      </Modal>

      {/* Save Key Modal */}
      <SaveKeyModal open={showSaveKeyModal} onClose={() => setShowSaveKeyModal(false)} apiKey={generatedApiKey} />
    </>
  );
};

export default CreateAPIKeyModal;
