import React from "react";
import StartFreeButton from "../buttons/StartFreeButton";

const SolutionIntro = () => {
  const firstStep = [
    "Add all document types you handle (e.g., invoices, contracts, revenueReport).",
    "Use a consistent naming convention (e.g., natural language, camelCase) for better accuracy.",
    "Align document types and naming conventions with your organization’s needs for optimal results.",
  ];

  const secondStep = [
    "Create the JSON format you want to receive.",
    "Supported types include Strings, Dates, Booleans, Tables (arrays), and Groups.",
    "Use a consistent naming convention (e.g., natural language, camelCase) for better accuracy.",
  ];

  const thirdStep = [
    "It's as simple as that.",
    "If you're done testing, create an API key and head to the docs to add it to your project.",
  ];

  const ExplanationCard = ({ number, title, body }) => (
    <article className="w-full">
      <header className="text-xl md:text-2xl font-medium flex items-center space-x-2 border-b pb-4">
        <div className="h-10 w-10 rounded-full bg-gray-700 text-white flex items-center justify-center">{number}</div>
        <h2>{title}</h2>
      </header>
      {body && (
        <section className="mt-4 space-y-4 text-base">
          {body.map((item, index) => (
            <p className="text-gray-500" key={index}>
              • {item}
            </p>
          ))}
        </section>
      )}
    </article>
  );

  const Header = ({ subtitle, title }) => (
    <header className="flex flex-col items-center">
      <h1 className="text-4xl font-semibold text-center leading-normal">{title}</h1>
      <p className="mt-4 mb-8 text-base max-w-lg text-gray-500 leading-relaxed text-center">{subtitle}</p>
      <div className="mb-12">
        <StartFreeButton />
      </div>
    </header>
  );

  return (
    <section className="flex w-full items-center justify-center flex-col py-16 text-gray-700 bg-gradient-to-b from-white to-neutral-50 px-4">
      <Header
        title="All-in-One Splitter & Extraction API"
        subtitle="Integrate Splitter and Extractor functions into one API for efficiency."
      />
      <div className="max-w-screen-md w-full relative mb-36 flex items-center justify-center group">
        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 px-2">
          <article className="w-full h-52 bg-gray-50 border rounded-lg bg-opacity-90 p-4 flex items-center flex-col justify-center text-center">
            <h3 className="text-xl font-semibold">No Pre-Training Required</h3>
            <p className="text-gray-400 mt-2">Our tool is ready to use out-of-the-box.</p>
          </article>
          <article className="w-full h-52 bg-gray-50 border rounded-lg bg-opacity-90 p-4 flex items-center flex-col justify-center text-center">
            <h3 className="text-xl font-semibold">Extract to JSON</h3>
            <p className="text-gray-400 mt-2">Convert PDF data to JSON for easy integration.</p>
          </article>
          <article className="w-full h-52 bg-gray-50 border rounded-lg bg-opacity-90 p-4 flex items-center flex-col justify-center text-center">
            <h3 className="text-xl font-semibold">Any Document Types</h3>
            <p className="text-gray-400 mt-2">Supports all document types, not just specific ones.</p>
          </article>
          <article className="w-full h-52 bg-gray-50 border rounded-lg bg-opacity-90 p-4 flex items-center flex-col justify-center text-center">
            <h3 className="text-xl font-semibold">High Accuracy</h3>
            <p className="text-gray-400 mt-2">Reliable data extraction surpassing other services.</p>
          </article>
        </div>
      </div>

      <Header
        title="How it Works"
        subtitle="Upload multiple documents and our AI will handle splitting, extracting, and analyzing."
      />
      <div className="space-y-12">
        <section className="max-w-screen-xl w-full flex flex-col-reverse md:flex-row md:items-center py-8 md:py-14 rounded-lg md:space-x-4">
          <div className="w-full flex justify-center mt-6 md:mt-0">
            <img
              src="https://s3.pardocs.com/landing/how_1.png"
              className="rounded-3xl object-contain shadow-lg border-2 border-sky-500"
              alt="Step 1: Add document types"
              draggable={false}
            />
          </div>
          <div className="w-full">
            <ExplanationCard number={1} title="Add Document Types" body={firstStep} />
          </div>
        </section>
        <section className="max-w-screen-xl w-full flex flex-col-reverse md:flex-row md:items-center py-8 md:py-14 rounded-lg md:space-x-4">
          <div className="w-full flex justify-center mt-6 md:mt-0">
            <img
              src="https://s3.pardocs.com/landing/how_2.png"
              className="rounded-3xl object-contain shadow-lg border-2 border-sky-500"
              alt="Step 2: Set schema for each document type"
              draggable={false}
            />
          </div>
          <div className="w-full">
            <ExplanationCard number={2} title="Set Schema for Each Document Type" body={secondStep} />
          </div>
        </section>
        <section className="max-w-screen-xl w-full flex flex-col-reverse md:flex-row md:items-center py-8 md:py-14 rounded-lg md:space-x-4">
          <div className="w-full flex justify-center mt-6 md:mt-0">
            <img
              src="https://s3.pardocs.com/landing/how_3.png"
              className="rounded-3xl object-contain shadow-lg border-2 border-sky-500"
              alt="Step 3: Test your model"
              draggable={false}
            />
          </div>
          <div className="w-full">
            <ExplanationCard number={3} title="Done! You Can Now Test Your Model" body={thirdStep} />
          </div>
        </section>
      </div>
    </section>
  );
};

export default SolutionIntro;
