import React from "react";
import { FaMapPin } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaDiscord } from "react-icons/fa6";

const Footer = () => {
  const exploreList = [
    { title: "About", url: "/about" },
    { title: "Pricing", url: "/pricing" },
    { title: "Contact", url: "/contact" },
    { title: "Developer Docs", url: "https://docs.pardocs.com/" },
    { title: "Terms of Service", url: "/legal/terms" },
    { title: "Privacy Policy", url: "/legal/privacy" },
  ];

  return (
    <>
      <footer className="w-full flex-shrink-0 flex flex-col items-center justify-center bg-gray-800 px-4">
        <div className="space-y-4 h-24 flex items-center w-full max-w-screen-xl border-b border-gray-600">
          <img
            src={"https://s3.pardocs.com/landing/pardocs_logo_white.png"}
            alt="ParDocs white logo"
            className="h-7 w-32 object-contain"
            draggable={false}
          />
        </div>
        <div
          style={{ minHeight: "24rem" }}
          className="max-w-screen-xl px-4 flex flex-col md:flex-row w-full h-full md:space-x-16 py-8"
        >
          {/* Explore Section */}
          <div
            style={{ minWidth: "16rem" }}
            className="text-sm text-gray-400 flex flex-col md:mt-0 mt-12 flex-shrink-0"
          >
            <h2 className="text-gray-200 text-lg font-semibold mb-8">Explore</h2>
            <div className="space-y-4 flex flex-col">
              {exploreList.map((item) => (
                <Link key={item.title} to={item.url} className="text-sm hover:text-white transition">
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
          {/* Contact Section */}
          <div className="text-sm text-gray-400 flex flex-col md:mt-0 mt-12 flex-shrink-0">
            <h2 className="text-gray-200 text-lg font-semibold mb-8">Contact</h2>

            <div className="flex space-x-3 mb-4">
              <FaMapPin className="mt-1" />
              <div>
                <p className="">122 Mapo-daero, Mapo-gu, Seoul, South Korea</p>
                <p className="">04213</p>
              </div>
            </div>
            {/* Email */}
            <div className="flex space-x-3 mb-4">
              <IoMdMail className="mt-1" />
              <div>
                <p>
                  <a href="mailto:johnk@traddocs.com" className="hover:text-white transition">
                    johnk@traddocs.com
                  </a>
                </p>
              </div>
            </div>
            {/* Discord Link */}
            <div className="flex space-x-3">
              <a href="https://discord.gg/xgEXkh7Rxk" target="_blank" rel="noopener noreferrer">
                <FaDiscord className="w-8 h-8 text-white" />
              </a>
            </div>
          </div>
          {/* Additional Info */}
          <div className="text-sm text-gray-400 flex flex-col md:mt-0 mt-12 justify-end w-full items-end">
            <img
              draggable={false}
              src={"https://s3.pardocs.com/landing/ms_dark.png"}
              alt="Microsoft partner logo"
              className="w-48"
            />
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="max-w-screen-xl w-full h-24 border-t border-gray-600 flex items-center justify-between text-gray-200 text-xs">
          <p>© {new Date().getFullYear()} TradDocs Inc. All rights reserved.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
