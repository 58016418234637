import React from "react";
import Partners from "../components/Landing/Partners";

const AboutUs = () => {
  const WelcomeSection = () => (
    <div className="w-full flex justify-center relative pt-32">
      <div className="max-w-screen-lg w-full h-full flex items-center px-4 md:px-16 z-20 text-center py-20">
        <div className="w-full flex flex-col justify-center items-center text-gray-700 break-keep">
          <h1
            style={{ whiteSpace: "pre-wrap" }}
            className="font-semibold text-4xl md:text-5xl leading-tight md:leading-snug tracking-[-0.01em]"
          >{`We understand the challenges of document processing`}</h1>

          <h2 className="mt-8 md:mt-10 break-keep text-sm md:text-lg max-w-xl text-gray-500 leading-relaxed">
            Meet the team behind ParDocs and discover how our cutting-edge technology is transforming document
            automation for businesses and individuals alike.
          </h2>
        </div>
      </div>
    </div>
  );

  const ImageSection = () => (
    <div className="w-full flex justify-center relative">
      <div className="max-w-screen-xl w-full h-full p-3 bg-gray-200 rounded-2xl">
        <div className="flex flex-col md:flex-row md:space-x-3 md:h-[48rem]">
          <div className="flex w-full md:w-2/3 h-full pb-3 md:pb-0">
            <img
              src="https://s3.pardocs.com/landing/team/team10.png"
              alt="Team members collaborating on a project"
              className="w-full h-full object-cover rounded-lg object-center"
            />
          </div>
          <div className="flex flex-col w-full md:w-1/3 md:h-[48rem]">
            <img
              src="https://s3.pardocs.com/landing/team/team1.jpg"
              alt="Team member working at a desk"
              className="flex w-full h-1/3 object-cover pb-3 rounded-lg"
            />
            <img
              src="https://s3.pardocs.com/landing/team/team8.png"
              alt="Close-up of a team discussion"
              className="flex w-full h-1/3 object-cover pb-3 rounded-lg"
            />
            <img
              src="https://s3.pardocs.com/landing/team/team6.png"
              alt="Team brainstorming session"
              className="flex w-full h-1/3 object-cover rounded-lg"
            />
          </div>
        </div>

        <div className="flex items-center flex-col h-20 justify-center">
          <img
            src="https://s3.pardocs.com/landing/pardocs_logo.png"
            alt="ParDocs logo"
            className="h-10 w-32 object-contain"
            draggable={false}
          />
          <p className="text-xs font-medium -mt-0.5 text-gray-500">by ParDocs</p>
        </div>
      </div>
    </div>
  );

  const PartnerSection = () => (
    <div className="w-full py-20 pb-12 bg-gray-100 flex justify-center text-gray-700 mt-16">
      <div className="max-w-screen-xl w-full h-full flex flex-col items-center justify-center px-4 md:px-16">
        <div className="w-full flex flex-col justify-center items-center text-gray-700 break-keep">
          <h1 style={{ whiteSpace: "pre-wrap" }} className="font-semibold text-3xl md:text-4xl text-center">
            {`ParDocs advances through\nsupport of these partners`}
          </h1>
        </div>
        <div className="flex md:flex-row flex-col space-y-8 md:space-y-0 md:space-x-12 items-center mt-20">
          <img
            src="https://s3.pardocs.com/landing/ms_startup.png"
            draggable={false}
            className="h-20 md:-mt-4"
            alt="Microsoft Startup Partner Logo"
          />
          <img
            src="https://s3.pardocs.com/landing/google_startup.png"
            draggable={false}
            className="h-8"
            alt="Google Startup Partner Logo"
          />
        </div>
      </div>
    </div>
  );

  const Vision = () => (
    <div className=" bg-gradient-to-b from-gray-100 to-white w-full py-32 flex justify-center text-gray-700">
      <div className="max-w-screen-xl w-full h-full flex flex-col items-center justify-center px-4 md:px-16">
        <div className="w-full flex flex-col justify-center items-center text-gray-700 break-keep">
          <h1 className="font-semibold text-3xl md:text-4xl leading-tight">Our Vision and Goals</h1>
          <p className="break-keep mt-12 text-center max-w-2xl text-lg leading-relaxed">
            At ParDocs, we envision a future where document processing is effortless and precise. Our mission is to
            streamline data extraction and automation through our advanced technology, freeing you from manual tasks and
            allowing you to focus on what truly matters. We strive to lead the industry in document automation,
            empowering users with efficient, reliable solutions.
          </p>
        </div>
        <div className="grid grid-cols-2 gap-12 mt-20 text-gray-600 text-sm">
          <div className="flex items-center space-x-4">
            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
            <p>Innovation and Expertise</p>
          </div>
          <div className="flex items-center space-x-4">
            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
            <p>Commitment to Quality</p>
          </div>
          <div className="flex items-center space-x-4">
            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
            <p>Customer-Centric Approach</p>
          </div>
          <div className="flex items-center space-x-4">
            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
            <p>Advanced Technology</p>
          </div>
          <div className="flex items-center space-x-4">
            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
            <p>Efficient Solutions</p>
          </div>
          <div className="flex items-center space-x-4">
            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
            <p>Global Vision</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <WelcomeSection />
      <ImageSection />
      <Partners />
      <PartnerSection />
      <Vision />
    </>
  );
};

export default AboutUs;
