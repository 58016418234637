import React, { useEffect, useState } from "react";
import DropComponentTest from "../dropComponent/DropComponentTest.jsx";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import { AiOutlineDownload } from "react-icons/ai";
import { MdContentCopy, MdEdit } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../utils/authAxios.js";
import { FaTrash } from "react-icons/fa";
import ChangeDocumentTypeModal from "../modals/ChangeDocumentTypeModal.jsx";

const TestSection = ({ extractorData, getExtractionData, setSelectedTestFiles, selectedTestFiles }) => {
  // const [selectedTestFiles, setSelectedTestFiles] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [copyStatus, setCopyStatus] = useState("Copy");
  const [isChangeDocumentModalOpen, setChangeDocumentModalOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const extractionId = params.get("ex_id");

  useEffect(() => {
    return () => {
      setTestResult(null);
    };
  }, [extractionId, setTestResult]);

  // Function to download testResult as a JSON file
  const downloadJSON = () => {
    if (testResult) {
      const blob = new Blob([JSON.stringify(testResult, null, 2)], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "testResult.json"; // Name of the file to be downloaded
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url); // Clean up the URL object
    }
  };

  const copyToClipboard = () => {
    if (testResult) {
      const jsonString = JSON.stringify(testResult, null, 2);
      navigator.clipboard
        .writeText(jsonString)
        .then(() => {
          setCopyStatus("Copied"); // Update the button status
          setTimeout(() => setCopyStatus("Copy"), 2000); // Reset status after 2 seconds
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };

  function findExtractionTypeById(extractionId, extractionDataArray) {
    for (let i = 0; i < extractionDataArray.length; i++) {
      if (extractionDataArray[i].template_id === extractionId) {
        return extractionDataArray[i].document_type;
      }
    }
    return "ID not found";
  }

  const deleteExtractor = () => {
    axios
      .delete(`/v1/templates/${extractionId}`)
      .then((response) => {
        navigate("/");
      })
      .catch((e) => console.log(e));
  };

  return (
    <div style={{ height: "calc(100svh - 3rem)" }} className="w-80 flex-shrink-0 h-full">
      <ChangeDocumentTypeModal
        open={isChangeDocumentModalOpen}
        setOpen={setChangeDocumentModalOpen}
        getExtractionData={getExtractionData}
        currentDocumentType={findExtractionTypeById(extractionId, extractorData)}
        extractionId={extractionId}
      />
      <div className="w-full h-full bg-white rounded-r-lg">
        <div className="text-gray-700 font-medium flex space-x-2 h-12 items-center border-b px-3">
          <p>Information</p>
        </div>
        <div className="my-2 w-full rounded-lg p-2 space-y-2 px-3">
          <div className="flex justify-between items-start space-x-3">
            <p className="text-xs text-gray-500 font-medium">Template Id</p>
            <div className="text-xs w-48 text-right">{extractionId}</div>
          </div>
          <div className="flex justify-between items-start space-x-3">
            <p className="text-xs text-gray-500 font-medium flex-shrink-0">Document Type</p>
            <div className="text-xs w-48 text-right">{findExtractionTypeById(extractionId, extractorData)}</div>
          </div>
        </div>

        <div className="uppercase text-xs text-gray-700 font-medium flex space-x-2 px-3">
          <p>Actions</p>
        </div>
        <div className="flex flex-col space-y-2 mt-2 px-3">
          <button
            onClick={() => setChangeDocumentModalOpen(true)}
            className="bg-gray-100 text-gray-600 hover:text-blue-500 text-sm font-medium h-9 border-none flex items-center justify-center rounded-lg transition space-x-2 px-4"
          >
            <MdEdit />
            <p className="text-xs">Edit Document Type</p>
          </button>
          <button
            onClick={deleteExtractor}
            className="bg-gray-100 text-red-600 hover:text-red-500 text-sm font-medium h-9 border-none flex items-center justify-center rounded-lg transition space-x-2 px-4"
          >
            <FaTrash className="w-2.5 h-2.5" />
            <p className="text-xs">Delete template</p>
          </button>
        </div>

        <div className="uppercase text-xs text-gray-700 font-medium flex space-x-2 px-3 mt-4">
          <p>
            TEST TEMPLATE <span className="text-xs text-gray-400">({extractionId})</span>
          </p>
        </div>
        <div className="my-2 px-3">
          <DropComponentTest
            selectedTestFiles={selectedTestFiles}
            setSelectedTestFiles={setSelectedTestFiles}
            extractorData={extractorData}
            isUploading={isUploading}
            setUploading={setUploading}
            setTestResult={setTestResult}
            extractionId={extractionId}
          />
        </div>

        {testResult && !isUploading && (
          <div className="mt-2 space-y-2 px-3">
            <div className="flex w-full items-center justify-between">
              <p className="text-xs">Results</p>
              <div className="flex items-center space-x-2">
                <button
                  className="flex items-center space-x-1 px-2 py-0.5 border rounded text-xs hover:bg-blue-100"
                  onClick={downloadJSON}
                >
                  <AiOutlineDownload />
                  <p>Download JSON</p>
                </button>
              </div>
            </div>
            <div className="relative">
              <button
                className="flex items-center space-x-1 px-2 py-0.5 border rounded text-xs hover:bg-blue-100 absolute top-2 right-4 z-20 bg-white"
                onClick={copyToClipboard}
              >
                <MdContentCopy />
                <p>{copyStatus}</p>
              </button>
              <div style={{ maxHeight: "calc(100svh - 29rem)" }} className="overflow-y-auto border rounded">
                <Editor
                  value={JSON.stringify(testResult, null, 2)}
                  readOnly
                  highlight={(code) => highlight(code, languages.js)}
                  className="bg-gray-100"
                  textareaClassName="outline-none"
                  padding={10}
                  style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 10,
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestSection;
