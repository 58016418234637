import React, { useContext } from "react";
import Members from "../Team/Members";
import { AuthContext } from "../../../context/authContext";
import moment from "moment";

const Team = () => {
  const { userState } = useContext(AuthContext);
  const userInfo = userState.user;

  const currentMonth = moment().format("MMMM");
  const nextMonth = moment().add(1, "months").format("MMMM");

  const panelHeight = "calc(100svh - 13.5rem)"; // "calc(100svh - 18rem)"

  const UsageCard = ({ title, value, reference, noProgress, percentage }) => (
    <div className="w-full h-full bg-gray-100 rounded-lg flex flex-col overflow-hidden">
      <div className={`${noProgress && "bg-transparent"} w-full bg-gray-300 h-2`}>
        <div
          style={{ width: percentage }}
          className={`${noProgress && "bg-transparent"} bg-blue-600 h-full rounded-r-full`}
        />
        <div className="p-3">
          <div className="w-full flex items-center justify-between text-sm">
            <p className="text-xs">{title}</p>
          </div>
          <div className="flex text-sm space-x-1 mt-2 items-end">
            <p className="text-lg font-medium">{value}</p>
            <p className="mb-1">{reference}</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full overflow-y-auto text-gray-700">
      <div className="w-full h-full overflow-y-auto flex justify-center bg-gray-100 text-gray-700">
        <div className="p-2 w-full max-w-screen-xl flex flex-col space-y-2">
          <div className="h-48 w-full flex space-x-2">
            <div className="h-48 w-full bg-white rounded-lg border p-4 flex flex-col">
              <div className="space-y-0.5 h-10">
                <p className="text-xl font-semibold">{userInfo.userName}'s Team</p>
                <div className="flex items-center space-x-2">
                  <p className="text-xs text-gray-400">
                    Current usage period ({currentMonth} 3 to {nextMonth} 2)
                  </p>
                  <div className="bg-gray-100 text-xs font-medium text-gray-500 px-2 py-1 rounded">
                    Free Trial Plan{" "}
                  </div>
                </div>
              </div>
              <div className="flex space-x-4 w-full h-full mt-8">
                <UsageCard title={"Checks"} value={"Free Trial"} reference={"/20 pages"} percentage={"100%"} />
                <UsageCard title={"Uploads"} value={"Free Trial"} reference={"/500 MB"} percentage={"100%"} />
                <UsageCard title={"Team members"} noProgress value={1} />
              </div>
            </div>
            <div className="h-48 w-48 bg-white rounded-lg border flex-shrink-0 bg-gradient-to-br from-fuchsia-400 to-fuchsia-300 text-7xl font-medium text-white flex items-center justify-center">
              {userInfo.userName.slice(0, 1)}
            </div>
          </div>

          <div className={`w-full space-x-4 flex`}>
            <div style={{ minHeight: panelHeight }} className="w-full bg-white rounded-lg h-full border p-4">
              <Members />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
