import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "../../utils/authAxios";
import { notification } from "antd";
import { FaCircleCheck } from "react-icons/fa6";
import Editor, { loader } from "@monaco-editor/react";

const ExtractorSectionCode = ({ extractorData, getExtractionData }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const extractionId = params.get("ex_id");
  const [jsonSaved, setJsonSaved] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [lastSavedData, setLastSavedData] = useState("");
  const [code, setCode] = useState("");
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    const customTheme = {
      base: "vs", // Can also be 'vs' or 'hc-black'
      inherit: true,
      rules: [],
      colors: {
        "editor.background": "#ffffff",
      },
    };
    loader.init().then((monacoInstance) => {
      monacoInstance.editor.defineTheme("customTheme", customTheme);
    });
  }, []);

  useEffect(() => {
    if (lastSavedData !== code) {
      setJsonSaved(false);
    }
    return () => {};
  }, [lastSavedData, code]);

  useEffect(() => {
    if (extractionId && extractorData.length > 0) {
      const extraction = extractorData.find((item) => item.template_id === extractionId);
      if (extraction) {
        setCurrentData(extraction);
        setCode(JSON.stringify(extraction.template_data, null, 2));
      }
    }
  }, [extractionId, extractorData]);

  const validateJSON = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const openNotification = () => {
    api.info({
      icon: <FaCircleCheck className="text-blue-500 w-6 h-6 opacity-90" />,
      message: <p className="text-base font-semibold text-gray-700">{"Schema saved"}</p>,
      description: <p className="text-sm text-gray-500 break-keep">for {currentData.document_type}</p>,
      placement: "top",
    });
  };

  const handleSave = () => {
    if (validateJSON(code)) {
      // Proceed with save operation
      axios
        .patch(`/v1/templates/${extractionId}`, {
          document_type: currentData.document_type,
          template_data: JSON.parse(code),
        })
        .then((response) => {
          setLastSavedData(code);
          getExtractionData();
          openNotification();
          setJsonSaved(true);
        })
        .catch((error) => {
          console.error("PUT EXTRACTIONS ERROR:", error);
        });
    } else {
      // Alert the user about invalid JSON
      alert(
        `Invalid JSON. Make sure to check the following and try again:\n\n` +
          `- All KEYS must be enclosed in double quotes.\n` +
          `- All STRINGS must be enclosed in double quotes.\n` +
          `- Remove all comments.\n` +
          `- All keys and values must be properly separated by a colon.\n` +
          `- All arrays and objects must be correctly closed with brackets.\n` +
          `- No trailing commas are allowed at the end of arrays or objects.`
      );
    }
  };

  return (
    <div className="w-full h-full">
      {contextHolder}
      <div className="w-full h-full">
        {!extractionId ? (
          <div className="flex flex-col w-full h-full bg-gray-100 rounded-lg items-center justify-center text-gray-500 px-6">
            <p className="text-xl mb-2">All-in-One Splitter and Extractor</p>
            <p className="mb-6 text-sm text-center">Add a document type in "DOCUMENT SPLITTER" to get started.</p>

            <img
              src="https://s3.pardocs.com/landing/extractor_placeholder.png"
              className="flex px-8"
              alt=""
              draggable={false}
            />
          </div>
        ) : (
          <div className="flex flex-col w-full h-full bg-white border rounded-lg overflow-hidden">
            <div className="px-3 pt-3 z-20">
              {!jsonSaved && (
                <div className="flex items-center space-x-2">
                  <p className="text-xs text-gray-500">Make sure to save your changes.</p>
                  <button
                    onClick={handleSave}
                    className="text-xs font-medium px-4 h-7 rounded-lg bg-blue-500 hover:bg-blue-400 transition text-white flex items-center"
                  >
                    <p>Save</p>
                  </button>
                </div>
              )}
            </div>
            <Editor
              className="rounded-lg pt-3 text-base"
              theme={"customTheme"}
              height="83.2vh"
              defaultLanguage="json"
              value={code}
              options={{
                scrollBeyondLastLine: false,
              }}
              onChange={(e) => setCode(e)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExtractorSectionCode;
