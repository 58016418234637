import "./App.css";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthContext } from "./context/authContext";
import SEOMetaTag from "./utils/SEOMetaTag";
import ScrollToTop from "./utils/scrollToTop";
import { AxiosInterceptor } from "./utils/authAxios";
import Landing from "./pages/Landing";
import Auth from "./pages/Auth/Auth";
import NavBar from "./components/NavBar";
import Main from "./pages/Main/Main";
import { pdfjs } from "react-pdf";
import AboutUs from "./pages/AboutUs";
import Pricing from "./pages/Pricing";
import ContactUs from "./pages/ContactUs";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Footer from "./components/Footer";

pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

function App() {
  const { userState } = useContext(AuthContext);

  if (userState.isAuthenticated)
    return (
      <AxiosInterceptor>
        <div style={{ height: "100svh" }} className="flex flex-col items-center w-full overflow-x-hidden">
          <ScrollToTop />
          <Routes>
            <Route path="/*" element={<Main />} />
          </Routes>
        </div>
      </AxiosInterceptor>
    );

  return (
    <AxiosInterceptor>
      <NavBar />
      <div className="flex flex-col items-center w-full overflow-x-hidden text-gray-800">
        <ScrollToTop />
        <Routes>
          <Route
            path="/*"
            element={
              <>
                <SEOMetaTag
                  title="ParDocs: Out-of-the-Box Document Parser"
                  description="Split and extract unstructured documents to JSON effortlessly – no pre-training required. ParDocs saves hours on data labeling so you can focus on building."
                  keywords="PDF document extraction, document splitting API, unstructured data extraction, JSON extraction API, no pre-training required, document automation API, AI document processing, efficient PDF tools, API for document extraction, automated PDF solutions"
                  url="https://www.pardocs.com"
                  imgsrc="https://s3.pardocs.com/landing/pardocs_og.png"
                />
                <Landing />
              </>
            }
          />
          <Route
            path="/about/*"
            element={
              <>
                <SEOMetaTag
                  title="About Us - ParDocs"
                  description="Learn more about ParDocs, the team behind our innovative document processing solution, and our commitment to transforming data extraction."
                  keywords="About ParDocs, document processing team, document automation company, ParDocs mission"
                  url="https://www.pardocs.com/about"
                />
                <AboutUs />
              </>
            }
          />
          <Route
            path="/pricing/*"
            element={
              <>
                <SEOMetaTag
                  title="Pricing - ParDocs"
                  description="Explore ParDocs' pricing plans, including a free plan, basic plan, and custom pay-as-you-go option. Find the best solution for your document processing needs."
                  keywords="ParDocs pricing, document processing pricing, API pricing plans, flexible pricing options"
                  url="https://www.pardocs.com/pricing"
                />
                <Pricing />
              </>
            }
          />
          <Route
            path="/contact/*"
            element={
              <>
                <SEOMetaTag
                  title="Contact Us - ParDocs"
                  description="Get in touch with ParDocs for inquiries, support, or assistance with document processing and automation. We're here to help you streamline your workflow."
                  keywords="ParDocs contact, document processing support, automation help, document automation assistance, ParDocs customer service"
                  url="https://www.pardocs.com/contact"
                />
                <ContactUs />
              </>
            }
          />
          <Route
            path="/legal/privacy"
            element={
              <>
                <SEOMetaTag
                  title="Privacy Policy - ParDocs"
                  description="Read ParDocs' Privacy Policy to understand how we handle your personal data and ensure your privacy. Learn about our data protection practices and your rights."
                  keywords="ParDocs Privacy Policy, data protection, personal data handling, privacy practices, user rights, data security"
                  url="https://www.pardocs.com/legal/privacy"
                />
                <Privacy />
              </>
            }
          />
          <Route
            path="/legal/terms"
            element={
              <>
                <SEOMetaTag
                  title="Terms of Service - ParDocs"
                  description="Review the Terms of Service for ParDocs. Understand the legal terms and conditions that apply to the use of our services and your rights and responsibilities."
                  keywords="ParDocs Terms of Service, user agreement, service terms, legal terms, usage policies, user rights"
                  url="https://www.pardocs.com/legal/terms"
                />
                <Terms />
              </>
            }
          />
          <Route path="/auth/*" element={userState.isAuthenticated ? <Navigate to="/" /> : <Auth />} />
        </Routes>
        <Footer />
      </div>
    </AxiosInterceptor>
  );
}

export default App;
