import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import axios from "../../utils/authAxios";
import moment from "moment";
import { FaLock } from "react-icons/fa6";
import { BiPlus } from "react-icons/bi";
import CreateAPIKeyModal from "../modals/CreateAPIKeyModal";

const Generate = () => {
  const { userState } = useContext(AuthContext);
  const userInfo = userState.user;
  const [isCreateAPIModalOpen, setCreateAPIModalOpen] = useState(false);
  const [existingApi, setExistingApi] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const getExistingApi = () => {
    axios
      .get(`/v1/auth/api_keys`)
      .then((response) => {
        setExistingApi(response.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getExistingApi();
    return () => {};
  }, []);

  function formatApiKey(apiKey) {
    const firstPart = apiKey.slice(0, 3);
    const lastPart = apiKey.slice(-4);
    return `${firstPart} ... ${lastPart}`;
  }

  const APITable = ({ data }) => (
    <table className="min-w-full bg-white">
      <thead className="text-xs border-b">
        <tr className="">
          <th className="py-2 px-4 text-left font-semibold text-gray-700 uppercase tracking-wider">NAME</th>
          <th className="py-2 px-4 text-left font-semibold text-gray-700 uppercase tracking-wider">SECRET KEY</th>
          <th className="py-2 px-4 text-left font-semibold text-gray-700 uppercase tracking-wider">CREATED</th>
          <th className="py-2 px-4 text-left font-semibold text-gray-700 uppercase tracking-wider">LAST USED</th>
          <th className="py-2 px-4 text-left font-semibold text-gray-700 uppercase tracking-wider">CREATED BY</th>
          <th className="py-2 px-4 text-left font-semibold text-gray-700 uppercase tracking-wider"></th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 text-xs">
        {data.map((item, index) => (
          <tr key={index}>
            <td className="py-3 px-4 whitespace-nowrap">My Test Key</td>
            <td className="py-3 px-4 whitespace-nowrap">{formatApiKey(item.api_key)}</td>
            <td className="py-3 px-4 whitespace-nowrap">{moment(item.api_key_created_at).format("MMM DD, YYYY")}</td>
            <td className="py-3 px-4 whitespace-nowrap">
              {item.api_key_last_used_at ? moment(item.api_key_last_used_at).format("MMM DD, YYYY") : "Never"}
            </td>
            <td className="py-3 px-4 whitespace-nowrap">{userInfo?.userName}</td>
            <td className="py-3 px-4 whitespace-nowrap">
              <div className="flex space-x-2">
                {/* <button className="text-blue-500 hover:text-blue-700 transition">Edit</button>
                <button className="text-red-500 hover:text-red-700 transition">Delete</button> */}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="w-full">
      <CreateAPIKeyModal open={isCreateAPIModalOpen} setOpen={setCreateAPIModalOpen} getExistingApi={getExistingApi} />

      <div className="bg-white">
        <div className="text-gray-700 font-medium flex space-x-2 h-12 items-center border-b px-3">
          <p>Generate API keys</p>
        </div>
        <div className="p-3">
          <p style={{ whiteSpace: "pre-wrap" }} className="text-xs mb-4 text-gray-500">
            {`As a project owner, you can view and manage all API keys for your project.\n\nKeep your API key confidential—do not share it or include it in public code. ParDocs may disable exposed keys.\n\nCheck the Usage page for details on each key's activity.`}
          </p>
          {!isLoading &&
            (existingApi.length === 0 ? (
              <div className="bg-gray-100 p-6 rounded-lg text-center items-center flex flex-col">
                <div className="rounded-lg bg-gray-300 flex items-center justify-center w-9 h-9 mb-4">
                  <FaLock className="text-gray-500" />
                </div>
                <p className="text-sm text-gray-500 mb-4">Create an API key to access the ParDocs API.</p>
                <button
                  onClick={() => setCreateAPIModalOpen(true)}
                  className="text-white bg-blue-500 hover:bg-blue-400 text-sm font-medium border-none flex items-center justify-center rounded-lg transition space-x-1 py-2 px-3 flex-shrink-0"
                >
                  <BiPlus className="w-5 h-5" />
                  <p className="font-medium text-xs">Create new secret key</p>
                </button>
              </div>
            ) : (
              <div className="space-y-4">
                <button
                  onClick={() => setCreateAPIModalOpen(true)}
                  className="text-white bg-blue-500 hover:bg-blue-400 text-sm font-medium border-none flex items-center justify-center rounded-lg transition space-x-1 py-2 px-3 flex-shrink-0"
                >
                  <BiPlus className="w-5 h-5" />
                  <p className="font-medium text-xs">Create new secret key</p>
                </button>
                <APITable data={existingApi} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Generate;
