import { Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { MdOutlineCancel } from "react-icons/md";
import axios from "../../utils/authAxios";

const SubscriptionModal = ({ setOpen, open }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [cardState, setCardState] = useState({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    focus: "",
  });

  const openNotification = () => {
    api.info({
      icon: <MdOutlineCancel className="text-red-500 w-6 h-6 opacity-90" />,
      message: <p className="text-base font-semibold text-gray-700">Payment did not go through.</p>,
      description: (
        <p className="text-sm text-gray-500 break-keep">It's a problem on our side. We'll fix it as soon as possible</p>
      ),
      placement: "topRight",
    });
  };

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;

    setCardState((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputFocus = (evt) => {
    setCardState((prev) => ({ ...prev, focus: evt.target.name }));
  };

  const isFormValid = () => {
    return (
      cardState.number.trim() !== "" &&
      cardState.expiry.trim() !== "" &&
      cardState.cvc.trim() !== "" &&
      cardState.name.trim() !== ""
    );
  };

  const proceedPayment = () => {
    const { number, name } = cardState;

    // Extract the first 4 and last 4 digits of the card number
    const firstFour = number.slice(0, 4);
    const lastFour = number.slice(-4);
    const maskedNumber = `${firstFour}****${lastFour}`;

    axios
      .post("/v1/johnsok/byung1-god/credit-card", { encryptCardNumber: maskedNumber, encryptCardName: name })
      .then((response) => {
        // handle success
      })
      .catch((e) => {
        openNotification();
      });
  };

  useEffect(() => {
    if (!open) {
      setSelectedPlan(null);
      setCardState({
        number: "",
        expiry: "",
        cvc: "",
        name: "",
        focus: "",
      });
    }
    return () => {};
  }, [open]);

  const handleCancel = () => {
    setOpen(false);
  };

  const PricingCard = ({ noAction, type, currentPlan, title, subtitle, featureArray }) => {
    return (
      <div className={` w-full rounded-lg border bg-white overflow-hidden shadow-lg`}>
        <div className="w-full h-52 flex flex-col justify-center items-center border-b px-4 bg-gray-50">
          <p className={`text-gray-700 font-medium text-lg`}>{title}</p>
          <p className={`text-gray-700 mt-4 text-sm opacity-70 text-center`}>{subtitle}</p>
          {!noAction &&
            (currentPlan ? (
              <button className="px-4 h-9 font-medium text-gray-500 mt-4 text-xs">Current plan</button>
            ) : (
              <button
                onClick={() => setSelectedPlan(type)}
                className="px-4 h-9 rounded-lg font-medium shadow bg-blue-500 text-white transition hover:bg-opacity-75 mt-4 text-xs"
              >
                Select plan
              </button>
            ))}
        </div>
        <div style={{ height: "16rem" }} className="py-4 px-4 space-y-3 bg-gray-100">
          {featureArray?.map((item, index) => (
            <div key={index} className="flex items-center justify-between">
              <p className="text-gray-500 break-keep text-xs">• {item.title}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="ParDocs pricing plans"
        onCancel={handleCancel}
        width={900}
        style={{ top: 30 }}
        className="px-4"
        footer={<></>}
      >
        {selectedPlan ? (
          <div>
            <div className="p-3">
              <div className="mb-4">
                <button
                  onClick={() => setSelectedPlan(null)}
                  className="flex items-center px-3 py-1 hover:bg-gray-100 hover:text-blue-500 transition text-gray-600 space-x-2 rounded-lg"
                >
                  <FaAngleLeft />
                  <p>Back to plans</p>
                </button>
              </div>

              <p className="text-xs text-gray-500 mb-4">Selected plan</p>

              <div className="w-full bg-gray-100 rounded-lg shadow p-4 px-6">
                <p className="font-medium text-gray-700">
                  {selectedPlan === "free"
                    ? "Free plan($0/month)"
                    : selectedPlan === "basic"
                    ? "Basic plan($9/month)"
                    : "Pay as you go"}
                </p>
              </div>

              <div>
                <p className="text-xs text-gray-500 my-4">Add payment method</p>
                <div className="flex space-x-4">
                  <Cards
                    number={cardState.number}
                    expiry={cardState.expiry}
                    cvc={cardState.cvc}
                    name={cardState.name}
                    focused={cardState.focus}
                  />
                  <form className="w-full">
                    <div className="flex space-x-3 w-full">
                      <div className="w-full">
                        <p className="text-xs text-gray-600 uppercase mb-1">Card number</p>
                        <input
                          type="text"
                          name="number"
                          placeholder="Card Number"
                          className="border px-3 py-1.5 w-full rounded"
                          value={cardState.number}
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                          maxLength="16" // Maximum length considering spaces (e.g., "1234 5678 9012 3456")
                          pattern="\d{4}\s\d{4}\s\d{4}\s\d{4}" // Pattern to match card number format
                          inputMode="numeric" // Ensures numeric keyboard on mobile devices
                        />
                      </div>
                      <div className="w-full">
                        <p className="text-xs text-gray-600 uppercase mb-1">Cardholder name</p>
                        <input
                          name="name"
                          placeholder="Cardholder Name"
                          className="border px-3 py-1.5 w-full rounded"
                          value={cardState.name}
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                        />
                      </div>
                    </div>

                    <div className="mt-3 flex space-x-3">
                      <div>
                        <p className="text-xs text-gray-600 uppercase mb-1">Expiry</p>
                        <input
                          type="text"
                          name="expiry"
                          placeholder="MM/YY"
                          className="border px-3 py-1.5 rounded w-32"
                          value={cardState.expiry}
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                          maxLength="4" // MM/YY format length
                          pattern="\d{2}/\d{2}" // Pattern to match expiry format
                          inputMode="numeric"
                        />
                      </div>
                      <div>
                        <p className="text-xs text-gray-600 uppercase mb-1">CVC</p>
                        <input
                          type="text"
                          name="cvc"
                          placeholder="CVC"
                          className="border px-3 py-1.5 rounded w-24"
                          value={cardState.cvc}
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                          maxLength="4" // Maximum 4 digits (for Amex, use 3 or 4)
                          pattern="\d{3,4}" // Pattern to match CVC format
                          inputMode="numeric"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="w-full flex justify-end mt-8">
                  <button
                    onClick={proceedPayment}
                    disabled={!isFormValid()} // Disable if the form is not valid
                    className={`text-white bg-blue-500 hover:bg-blue-400 text-sm font-medium border-none flex items-center justify-center rounded-lg transition space-x-1 py-3 px-3 flex-shrink-0 w-full ${
                      !isFormValid() ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    <p className="font-medium text-xs">Proceed</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex w-full space-x-4 my-10 px-8">
            <PricingCard
              currentPlan
              type="free"
              title="Free Plan ($0/month)"
              subtitle="Limited usage and quota of paid services"
              featureArray={[
                { title: "40 pages a month" },
                { title: "Single file upload max 20 pages" },
                { title: "Single file upload max 40MB" },
              ]}
            />
            <PricingCard
              type="basic"
              title="Basic Plan ($9/month)"
              subtitle="Additional quota and pages for personal projects"
              featureArray={[
                { title: "190 pages a month" },
                { title: "Single file upload max 50 pages" },
                { title: "Single file upload max 100MB" },
              ]}
            />
            <PricingCard
              type="pay"
              title="Pay as you go"
              subtitle="Extended usage and quota of paid services"
              featureArray={[
                { title: "No limit per month" },
                { title: "Single file upload max 100 pages" },
                { title: "No limit on file size" },
              ]}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default SubscriptionModal;
