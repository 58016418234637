import React, { useEffect, useState } from "react";
import SplitterSection from "../../../components/Dashboard/SplitterSection.jsx";
import ExtractorSection from "../../../components/Dashboard/ExtractorSection.jsx";
import TestSection from "../../../components/Dashboard/TestSection.jsx";
import axios from "../../../utils/authAxios.js";
import { PiArrowBendUpLeftBold } from "react-icons/pi";

const Dashboard = () => {
  const [extractorData, setExtractorData] = useState([]);
  const [selectedTestFiles, setSelectedTestFiles] = useState([]);

  const getExtractionData = () => {
    axios
      .get(`/v1/templates`)
      .then((response) => {
        setExtractorData(response.data);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getExtractionData();
    return () => {};
  }, []);

  return (
    <div style={{ height: "calc(100svh - 3rem)" }} className="w-full">
      <div className="w-full h-full bg-gray-100 flex px-2 pb-2">
        <SplitterSection
          extractorData={extractorData}
          getExtractionData={getExtractionData}
          setSelectedTestFiles={setSelectedTestFiles}
        />

        <div className="bg-white rounded-lg w-full overflow-hidden border">
          {extractorData.length === 0 ? (
            <div className="flex flex-col w-full h-full items-center justify-center text-gray-500 px-6 relative">
              <div className="absolute left-0 top-12">
                <div>
                  <PiArrowBendUpLeftBold className="w-10 h-10 text-purple-500" />
                  <div className="ml-4 px-3 py-2 -mt-2 border-2 rounded-lg border-purple-400 text-sm bg-purple-100 text-purple-500">
                    Create your first template here!
                  </div>
                </div>
              </div>
              <p className="text-xl mb-6">All-in-One Document Parser</p>
              <div className="space-y-3 text-xs">
                <div className="flex items-center space-x-2">
                  <div className="w-5 h-5 rounded-full bg-gray-400 flex items-center justify-center font-medium text-gray-200">
                    1
                  </div>
                  <p className="text-gray-500">
                    Upload to <span className="font-medium">'Analyze Document'</span> to auto-create a schema.
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-5 h-5 rounded-full bg-gray-400 flex items-center justify-center font-medium text-gray-200">
                    2
                  </div>
                  <p className="text-gray-500">
                    Refine your schema in the <span className="font-medium">'Template Schema'</span> section.
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-5 h-5 rounded-full bg-gray-400 flex items-center justify-center font-medium text-gray-200">
                    3
                  </div>
                  <p className="text-gray-500">
                    Test your model in the <span className="font-medium">'Test Template'</span> section.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex w-full divide-x">
              <ExtractorSection extractorData={extractorData} getExtractionData={getExtractionData} />
              <TestSection
                extractorData={extractorData}
                getExtractionData={getExtractionData}
                setSelectedTestFiles={setSelectedTestFiles}
                selectedTestFiles={selectedTestFiles}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
