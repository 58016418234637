import React, { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import PlanButton from "../PlanButton";
import moment from "moment";

const Usage = () => {
  const { userState } = useContext(AuthContext);
  const userInfo = userState.user;
  const currentMonth = moment().format("MMMM");
  const nextMonth = moment().add(1, "months").format("MMMM");

  return (
    <div>
      <div className="text-gray-700 font-medium flex h-12 items-center border-b px-3 space-x-3">
        <p>Usage</p>
        <div className="text-gray-700 text-xs bg-gray-100 rounded-lg px-3 py-1">Current month ({currentMonth})</div>
      </div>
      <div className="w-full bg-white p-3 flex justify-between items-center">
        <div className="flex space-x-4 items-center">
          <div className="h-28 w-28 bg-white rounded-xl border flex-shrink-0 bg-gradient-to-br from-fuchsia-400 to-fuchsia-300 text-6xl font-medium text-white flex items-center justify-center">
            {userInfo.userName.slice(0, 1)}
          </div>
          <div className="">
            <p className="text-lg font-medium">{userInfo.userName}'s Team</p>
            <div className="text-xs text-gray-400 flex-shrink-0 mb-2">
              Current usage period ({currentMonth} 3 to {nextMonth} 2)
            </div>
            <div className="w-64">
              <PlanButton type="white" />
            </div>
          </div>
        </div>
      </div>

      <div className="p-3">
        <div className="font-medium text-gray-700 text-sm mb-2">Cost</div>
        <div className="font-medium bg-gray-100 p-3 mb-3 text-sm text-gray-500 rounded-lg">This month: $0</div>
        <div className="px-3 py-2 text-xs flex min-w-48 items-center space-x-3 font-medium bg-gray-100 rounded-lg">
          <div className="w-48 h-full text-gray-500">Date</div>
          <div className="w-48 h-full text-gray-500">Upload</div>
          <div className="w-48 h-full text-gray-500">Splitter</div>
          <div className="w-48 h-full text-gray-500">Extractor</div>
        </div>

        <div className="font-medium text-gray-700 text-sm mt-12 mb-2">Activity</div>

        <div className="px-3 py-2 text-xs flex min-w-48 items-center space-x-3 font-medium bg-gray-100 rounded-lg">
          <div className="w-48 h-full text-gray-500">Date</div>
          <div className="w-48 h-full text-gray-500">Upload</div>
          <div className="w-48 h-full text-gray-500">Splitter</div>
          <div className="w-48 h-full text-gray-500">Extractor</div>
        </div>
      </div>
    </div>
  );
};

export default Usage;
