import React from "react";
import { Link } from "react-router-dom";

const StartFreeButton = ({ mode = "light" }) => {
  return (
    <Link className="flex-shrink-0" to={"/auth/signup"}>
      <button
        className={`${
          mode === "dark" ? "text-gray-700 bg-white" : "bg-gray-700 text-white"
        } text-base font-normal h-9 px-3 md:px-5 rounded-full transition flex-shrink-0 hover:brightness-125`}
      >
        Start for free
      </button>
    </Link>
  );
};

export default StartFreeButton;
