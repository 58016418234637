import React from "react";
import { FaLock } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import PlanButton from "../PlanButton";

const ApiKeysLeftPanel = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const getClassNames = (path) => {
    return currentPath === path
      ? "h-10 flex flex-col items-start justify-center px-3 text-xs text-blue-600 bg-gray-200 w-full rounded-lg"
      : "h-10 flex flex-col items-start justify-center px-3 text-xs text-gray-500 hover:bg-gray-200 w-full rounded-lg";
  };

  return (
    <div className="w-52 flex-shrink-0 h-full">
      <div className="w-full h-full overflow-y-auto pr-2">
        <div className="uppercase text-xs text-gray-700 font-medium px-3 h-12 flex items-center space-x-2">
          <FaLock className="w-2.5 h-2.5" />
          <p>API Keys</p>
        </div>
        <div style={{ height: "calc(100svh - 10.55rem)" }} className="w-full mb-2">
          <Link to={"/api-keys/generate"}>
            <button className={getClassNames("/api-keys/generate")}>
              <p className="line-clamp-1 text-left">API Keys</p>
            </button>
          </Link>
          <Link to={"/api-keys/usage"}>
            <button className={getClassNames("/api-keys/usage")}>
              <p className="line-clamp-1 text-left">Usage</p>
            </button>
          </Link>
        </div>
        <PlanButton />
      </div>
    </div>
  );
};

export default ApiKeysLeftPanel;
