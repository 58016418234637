import React from "react";
import Welcome from "../components/Landing/Welcome";
import Partners from "../components/Landing/Partners";
import SolutionIntro from "../components/Landing/SolutionIntro";
import CheckScopeIntro from "../components/Landing/CheckScopeIntro";

const Landing = () => {
  return (
    <div className="w-full flex items-center flex-col relative pt-20 bg-neutral-50">
      <div className="w-full z-20 flex flex-col items-center">
        <Welcome />
        <Partners />
        <SolutionIntro />
        <CheckScopeIntro />
      </div>
    </div>
  );
};

export default Landing;
